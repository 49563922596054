import React, { useState, useEffect } from "react";
import { Paper, Grid, TextField, Autocomplete, Button, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Swal from "sweetalert2";
import { getCiudades, buscarTransportistas, getAgentes } from "../../services/ManifiestosServices";
import { useSelector } from "react-redux";
import moment from "moment";
import { guardarDetalleCarga } from "../../services/operacionesService";

function InformeCargaDiaria() {
  const userInfo = useSelector((state) => state.user.userInfo);
  const nombreUser = userInfo ? userInfo.name : null;
  const idUser = userInfo ? userInfo.id : null;

  const [fechaActual, setFechaActual] = useState("");
  const [ciudadesOption, setCiudadesOption] = useState([]);
  const [transporteOption, setTransporteOption] = useState([]);
  const [transporte, setTransporte] = useState(null);
  const [filas, setFilas] = useState([]);

  const tipoCargaOptions = [{ label: "CARGA VARIA" }, { label: "CARGA PELIGROSA" },{ label: "NOVOFARMA" }];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fechaActual = moment().format("YYYY-MM-DD");
        setFechaActual(fechaActual);

        const dataCiudades = await getCiudades();
        const ciudadesOption = dataCiudades.map((ciudad) => ({
          value: ciudad.IATA,
          label: ciudad.NOMBRE,
        }));
        setCiudadesOption(ciudadesOption);

        const dataTransporte = await buscarTransportistas();
        const transporteOption = dataTransporte.map((transporte) => ({
          value: transporte.COD_CHOFER,
          label: transporte.NOMBRE,
        }));
        setTransporteOption(transporteOption);
      } catch {}
    };
    fetchData();
  }, [userInfo]);

  const agregarFila = () => {
    setFilas([
      {
        destino: null,
        agente: null,
        tipoCarga: null,
        sacos: "",
        bultos: "",
        pallets: "",
        nota: "",
        agentesOption: [],
      },
      ...filas, // Agregamos la nueva fila al inicio
    ]);
  };

  const eliminarFila = (index) => {
    const nuevasFilas = filas.filter((_, i) => i !== index);
    setFilas(nuevasFilas);
  };

  const manejarCambio = (index, campo, valor) => {
    const nuevasFilas = [...filas];
    nuevasFilas[index][campo] = valor;
    setFilas(nuevasFilas);
  };

  const manejarCambioDestino = async (index, nuevoDestino) => {
    const nuevasFilas = [...filas];
    nuevasFilas[index].destino = nuevoDestino;

    if (nuevoDestino) {
      const dataAgentes = await getAgentes(nuevoDestino.value);
      const agentesOption = dataAgentes.map((agente) => ({
        value: agente.id,
        label: agente.AGENTE_ASIGNADO,
      }));
      nuevasFilas[index].agentesOption = agentesOption;
    } else {
      nuevasFilas[index].agentesOption = [];
    }

    setFilas(nuevasFilas);
  };

  const enviarDatos = async () => {
    const filasInvalidas = filas.filter((fila) => !fila.destino || !fila.agente || !fila.tipoCarga);

    const filasInvalidas2 = filas.filter((fila) => !fila.sacos && !fila.bultos && !fila.pallets);

    if (!transporte) {
      Swal.fire({
        icon: "error",
        title: "Transporte no seleccionado",
        text: "Por favor, selecciona el Transporte antes de enviar los datos.",
      });
      return;
    }

    if (filasInvalidas.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Datos incompletos",
        text: "Por favor, asegúrate de que todas las filas tengan Destino, Agente y Tipo de Carga seleccionados.",
      });
      return;
    }

    if (filasInvalidas2.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Datos incompletos",
        text: "Por favor, asegúrate de que al menos uno de los campos Sacos, Bultos o Pallets tenga un valor en cada fila.",
      });
      return;
    }

    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "Estás a punto de enviar los datos. ¿Deseas continuar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, enviar",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      for (const fila of filas) {
        const data = {
          transporte: transporte?.value,
          transporteNombre: transporte?.label,
          destino: fila.destino?.value,
          destinoNombre: fila.destino?.label,
          agente: fila.agente?.value,
          tipoCarga: fila.tipoCarga?.label,
          sacos: fila.sacos ? Number(fila.sacos) : 0,
          bultos: fila.bultos ? Number(fila.bultos) : 0,
          pallets: fila.pallets ? Number(fila.pallets) : 0,
          nota: fila.nota || "",
          id: idUser,
          fecha: fechaActual,
        };

        try {
          const response = await guardarDetalleCarga(data);
          console.log(response);
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Ocurrió un error al enviar los datos. Por favor, intenta nuevamente.",
          });
          return;
        }
      }

      setFilas([]);
      setTransporte(null);

      Swal.fire({
        icon: "success",
        title: "Datos enviados",
        text: "Todas las filas se han enviado correctamente.",
      });
    } else {
      Swal.fire({
        icon: "info",
        title: "Envío cancelado",
        text: "No se ha enviado ningún dato.",
      });
    }
  };

  return (
    <div>
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Autocomplete
              disableClearable
              value={transporte}
              onChange={(event, newValue) => setTransporte(newValue)}
              options={transporteOption}
              isOptionEqualToValue={(option, value) => option?.value === value?.value || value === null}
              renderInput={(params) => <TextField {...params} label="Transporte" />}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField disabled size="small" label="RESPONSABLE" value={nombreUser} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              fullWidth
              type="date"
              size="small"
              label="Fecha"
              value={fechaActual}
              onChange={(event) => setFechaActual(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={agregarFila}
          startIcon={<AddCircleOutlineIcon />}
          disabled={!transporte}
          style={{ marginRight: "10px" }}
        >
          Agregar Fila
        </Button>
        <Button variant="contained" color="success" onClick={enviarDatos} disabled={filas.length === 0}>
          Enviar Datos
        </Button>
      </Paper>
      {filas.map((fila, index) => (
        <Paper key={index} style={{ padding: "10px", marginBottom: "15px" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Autocomplete
                disableClearable
                value={fila.destino}
                options={ciudadesOption}
                onChange={(event, newValue) => manejarCambioDestino(index, newValue)}
                renderInput={(params) => <TextField {...params} label="Destino" />}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Autocomplete
                disableClearable
                value={fila.agente}
                options={fila.agentesOption}
                onChange={(event, newValue) => manejarCambio(index, "agente", newValue)}
                renderInput={(params) => <TextField {...params} label="Agente" />}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Autocomplete
                disableClearable
                value={fila.tipoCarga}
                options={tipoCargaOptions}
                onChange={(event, newValue) => manejarCambio(index, "tipoCarga", newValue)}
                renderInput={(params) => <TextField {...params} label="Tipo Carga" />}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextField
                size="small"
                label="Sacos"
                value={fila.sacos}
                onChange={(event) => manejarCambio(index, "sacos", event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextField
                size="small"
                label="Cajas"
                value={fila.bultos}
                onChange={(event) => manejarCambio(index, "bultos", event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextField
                size="small"
                label="Pallets"
                value={fila.pallets}
                onChange={(event) => manejarCambio(index, "pallets", event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                size="small"
                label="Nota"
                value={fila.nota}
                onChange={(event) => manejarCambio(index, "nota", event.target.value)}
                fullWidth
                multiline
                rows={1}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <IconButton sx={{ color: "#DA251C" }} onClick={() => eliminarFila(index)} size="small">
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </div>
  );
}

export default InformeCargaDiaria;
