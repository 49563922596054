import React, { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";

const TablaManifiestosAgentes = ({ columns, rows, loading }) => {
  // Memoriza las filas ordenadas para evitar re-render innecesarios
  const sortedRows = useMemo(() => [...rows].reverse(), [rows]);

  // Estado para la paginación
  const [pageSize, setPageSize] = useState(10); // Máximo de 10 filas por página

  return (
    <div>
      <Box sx={{ width: "100%", height: "550px" }}>
        <DataGrid
          rows={sortedRows}
          columns={columns}
          loading={loading}
          checkboxSelection={false}
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          disableColumnFilter
          getRowId={(row) => row.ID}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          pagination
          pageSizeOptions={[10, 20, 50]} // Opciones de cantidad de filas por página
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              overflowY: "auto",
            },
          }}
        />
      </Box>
    </div>
  );
};

export default TablaManifiestosAgentes;
