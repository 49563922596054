import React from "react";
import { useEffect } from "react";
import { Button, Grid, TextField, Autocomplete, Paper } from "@mui/material";
import { getCiudades, getAgentes } from "../../../services/ManifiestosServices";
import { ChoferesPorAgente } from "../../../services/ControlChoferesService";
import { obtenerOrdenesPendientesChofer } from "../../../services/ControlChoferesService";
import TablaManifiestos from "../../../components/OperacionesComponentes/componentesManifiestos/TablaManifiestos";
import { toast } from "react-toastify";
//import { useSelector } from "react-redux";

const RetirosChoferes = () => {
  const [selectedCiudad, setSelectedCiudad] = React.useState(null);
  const [ciudadesOption, setCiudadesOption] = React.useState([]);
  const [selectedAgente, setSelectedAgente] = React.useState(null);
  const [agentesOption, setAgentesOption] = React.useState([]);
  const [selectedChofer, setSelectedChofer] = React.useState(null);
  const [choferesOption, setChoferesOption] = React.useState([]);
  const [dataOrdenes, setDataOrdenes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE_CLIENTE", headerName: "CLIENTE", width: 270 },
    { field: "GUIA", headerName: "GUIA", width: 100 },
    { field: "NOMBRE_ORIGEN", headerName: "REMITENTE", width: 270 },
    { field: "CONTACTO", headerName: "CONTACTO_ORIGEN", width: 200 },
    { field: "DIRECCION_ORIGEN", headerName: "DIRECCION", width: 270 },
    { field: "NOMBRE_COMUNA_ORIGEN", headerName: "COMUNA", width: 160 },
    { field: "NOTA", headerName: "NOTA", width: 150 },
    {
      field: "FH_DIGITACION",
      headerName: "FH DIGITACION",
      width: 160,
      valueGetter: (params) => {
        if (!params.value) return "";
        return new Date(params.value).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    { field: "COD_ESTADO", headerName: "ULTIMA GESTION", width: 200 },
    { field: "FH_GESTION", headerName: "FECHA DE GESTION", width: 200 },
  ];

  useEffect(() => {
    const ciudades = async () => {
      try {
        const dataCiudades = await getCiudades();
        const ciudadesOption = dataCiudades.map((ciudades) => ({
          value: ciudades.IATA,
          label: ciudades.NOMBRE,
        }));
        setCiudadesOption(ciudadesOption);
      } catch {}
    };
    ciudades();
  }, []);

  const handleChangeCiudades = async (event, newValue) => {
    setDataOrdenes([]);
    setSelectedAgente(null);
    setSelectedChofer(null);
    setSelectedCiudad(newValue);
    const dataAgentes = await getAgentes(newValue.value);
    console.log("Agentes: ", dataAgentes);
    const agentesOption = dataAgentes.map((agentes) => ({
      value: 0,
      label: agentes.AGENTE_ASIGNADO,
    }));
    setAgentesOption(agentesOption);
  };
  const handleChangeAgentes = async (event, newValue) => {
    setDataOrdenes([]);
    setSelectedChofer(null);
    setSelectedAgente(newValue);
    console.log("Agente: ", newValue.label);
    try {
      const dataChoferes = await ChoferesPorAgente(newValue.label);
      if (dataChoferes && dataChoferes.length > 0) {
        console.log("Choferes: ", dataChoferes);
        const choferesOption = dataChoferes.map((choferes) => ({
          value: choferes.COD_CHOFER,
          label: choferes.NOMBRE,
        }));
        setChoferesOption(choferesOption);
      }
    } catch {setChoferesOption([]);}
  };
  const handleChangeChoferes = (event, value) => {
    setDataOrdenes([]);
    setSelectedChofer(value);
  };

  const handleBuscar = async () => {

    if(!selectedCiudad || !selectedAgente || !selectedChofer){
      toast.warning("Debe seleccionar Ciudad, Agente y Chofer para buscar");
      return;
    }

    setLoading(true);
    const response = await obtenerOrdenesPendientesChofer(selectedChofer.value);

    if (response.length === 0) {
      toast.warning("No existen órdenes para este chofer");
      setLoading(false);
      return;
    }

    // Ordenar las órdenes: las que tienen ULTIMA GESTION arriba
    const sortedData = response.sort((a, b) => {
      if (b.COD_ESTADO && !a.COD_ESTADO) {
        return -1; // b tiene gestión, a no -> b va primero
      } else if (!b.COD_ESTADO && a.COD_ESTADO) {
        return 1; // a tiene gestión, b no -> a va primero
      } else {
        return 0; // Mantener el orden si ambos tienen o no tienen gestión
      }
    });

    setDataOrdenes(sortedData);
    setLoading(false);
    console.log("Órdenes Pendientes Ordenadas: ", sortedData);
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disableClearable
                value={selectedCiudad}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={ciudadesOption}
                onChange={handleChangeCiudades}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="CIUDAD" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disableClearable
                value={selectedAgente}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={agentesOption}
                onChange={handleChangeAgentes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="AGENTE" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disableClearable
                value={selectedChofer}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={choferesOption}
                onChange={handleChangeChoferes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="CHOFER" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "90%",
                  backgroundColor: "#041562",
                }}
                onClick={handleBuscar}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid>
          <Grid style={{ marginTop: "10px" }}>
            <TablaManifiestos rows={dataOrdenes} columns={columns} loading={loading} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default RetirosChoferes;
