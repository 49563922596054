import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import ManifiestoMasivoRetornoCliente from "../../componentesManifiestoRetornoClientes/ManifiestoMasivoRetornoClientes";
import ManifiestoIndividualRetornoClientes from "../../componentesManifiestoRetornoClientes/ManifiestoIndividualRetornoClientes";

const RetornoClientes = () => {

  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const tipo_usuario = userInfo ? userInfo.tipo_usuario : null;
  const [selectedModule, setSelectedModule] = useState("");

  const renderModule = () => {
    switch (selectedModule) {
      case "ManifiestoIndividualRetornoClientes":
        return <ManifiestoIndividualRetornoClientes />;
      case "ManifiestoMasivoRetornoCliente":
        return <ManifiestoMasivoRetornoCliente />;
      default:
        return <Typography variant="h6">Seleccione un módulo para mostrar</Typography>;
    }
  };

  return (
    <Box p={1}>
      <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
        <Button
          onClick={() => setSelectedModule("ManifiestoIndividualRetornoClientes")}
          sx={{
            backgroundColor: selectedModule === "ManifiestoIndividualRetornoClientes" ? "#041562" : undefined,
            color: selectedModule === "ManifiestoIndividualRetornoClientes" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "ManifiestoIndividualRetornoClientes" ? "#DA251C" : undefined,
            },
          }}
        >
          Manifiesto Individual
        </Button>

        <Button
          onClick={() => setSelectedModule("ManifiestoMasivoRetornoCliente")}
          sx={{
            backgroundColor: selectedModule === "ManifiestoMasivoRetornoCliente" ? "#041562" : undefined,
            color: selectedModule === "ManifiestoMasivoRetornoCliente" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "ManifiestoMasivoRetornoCliente" ? "#DA251C" : undefined,
            },
          }}
        >
          Manifiesto Masivo
        </Button>


      </ButtonGroup>
      <Box mt={1}>{renderModule()}</Box>
    </Box>
  );

};
export default RetornoClientes;
