import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, TextField, Checkbox, Button, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  buscarComunasChoferManifiestoRuta,
  insertManifiesto,
  getChoferesXId,
  downloadManifestPdfGlobal,
  getPatentes,
} from "../../../services/ManifiestosServices";
import { buscarOrdenesComunas, insertPickingManifiestoMasivo, buscarChoferes } from "../../../services/ManifiestoComunaServices";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import TablaManifiestos from "../../../components/OperacionesComponentes/componentesManifiestos/TablaManifiestos";
import DeleteIcon from "@mui/icons-material/Delete";
import { AutocompletePersonalizado } from "../../../components/OperacionesComponentes/componentesVisuales/UtilesPersonalizados";
import { handleChangePatente } from "../../../components/OperacionesComponentes/componentesManifiestoRuta/EventHandler";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ManifiestoComuna = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const [choferes, setChoferes] = useState([]);
  const [peonetas, setPeonetas] = useState([]);
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [comunas, setComunas] = useState([]);
  const [selectedComunas, setSelectedComunas] = useState([]);
  const [codigoChofer, setCodigoChofer] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [selectedPeoneta, setSelectedPeoneta] = useState(null);
  const [idPeoneta, setIdPeoneta] = useState("");
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [patentes, setPatentes] = useState([]);
  const [selectedPatente, setSelectedPatente] = useState("");

  useEffect(() => {
    const fetchChoferes = async () => {
      try {
        let choferesData = [];
        
                if (userInfo.iata_agentes !== "SCL") {
                  choferesData = await buscarChoferes(userInfo.AGENTE_ASIGNADO);
                } else {
                  choferesData = await getChoferesXId(id_user);
                }
        setChoferes(choferesData);
        const choferesOptions = choferesData.map((choferes) => ({
          value: choferes.COD_CHOFER,
          label: choferes.NOMBRE,
        }));
        setPeonetas(choferesOptions);
      } catch (error) {
        console.error("Failed to fetch choferes", error);
      }
    };
    fetchChoferes();
  }, [id_user, userInfo]);

  useEffect(() => {
    const Patentes = async () => {
      try {
        const patentesData = await getPatentes();
        const patentesOptions = patentesData.map((patentes) => ({
          value: patentes.ID,
          label: patentes.PATENTE,
        }));
        console.log(patentesOptions);
        setPatentes(patentesOptions);
      } catch {}
    };
    Patentes();
  }, []);

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "GUIA", headerName: "Guias", width: 200 },
    {
      field: "NOMBRE",
      headerName: "Destino",
      width: 300,
      renderCell: (params) => {
        const nombre = params.row.NOMBRE;
        const nombreOrigen = params.row.NOMBRE_RETIRO;
        return nombreOrigen === "" ? nombre : nombreOrigen;
      },
    },
    {
      field: "DIRECCION",
      headerName: "Dirección",
      width: 300,
      renderCell: (params) => {
        const direccion = params.row.DIRECCION;
        const direccionRetiro = params.row.DIRECCION_RETIRO;
        return direccionRetiro === "" ? direccion : direccionRetiro;
      },
    },
    { field: "CIUDAD_ORIGEN", headerName: "Origen", width: 200 },
    { field: "CIUDAD_DESTINO", headerName: "Destino", width: 200 },
    {
      field: "FH_DIGITACION",
      headerName: "Fecha",
      width: 200,
      renderCell: (params) => {
        const fecha = new Date(params.value); // Convertir la fecha a objeto Date
        return fecha.toLocaleDateString("es-ES", { day: "2-digit", month: "2-digit", year: "numeric" }); // Formateo a dd-mm-yyyy
      },
    },
    {
      field: "Eliminar",
      headerName: "Eliminar",
      width: 150,
      renderCell: (params) => (
        <DeleteIcon style={{ color: "#DA251C", cursor: "pointer" }} onClick={() => handleEliminarOrden(params.row.OD)}>
          Eliminar
        </DeleteIcon>
      ),
    },
  ];

  const handleEliminarOrden = (od) => {
    const nuevaListaOrdenes = ordenesDataTabla.filter((orden) => orden.OD !== od);
    setOrdenesDataTabla(nuevaListaOrdenes);
  };

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      const options = {
        timeZone: "America/Santiago",
        hour12: false,
      };

      const fecha = fechaActual.toLocaleDateString("es-CL", options);

      const [dia, mes, año] = fecha.split("-");

      const nuevaFecha = `${año}-${mes}-${dia}`;

      const hora = fechaActual.toLocaleTimeString("es-CL", {
        ...options,
        hour12: false,
      });

      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;

      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null;
    }
  };

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: message,
    });
  };

  const resetState = () => {
    setSelectedChofer(null);
    setSelectedPeoneta(null);
    setSelectedComunas([]);
    setLoading(false);
    setOrdenesDataTabla([]);
    setSelectedPatente("");
  };

  const handleChangeChofer = async (newValue) => {
    const dataComunas = await buscarComunasChoferManifiestoRuta(newValue.COD_CHOFER);
    if (newValue != null) {
      setCodigoChofer(newValue.COD_CHOFER);
      setComunas(dataComunas);
    }
  };

  const handleChangePeoneta = (event, newValue) => {
    if (newValue === null) {
      setSelectedPeoneta(null);
      setIdPeoneta("");
    } else {
      setSelectedPeoneta(newValue);
      setIdPeoneta(newValue.value);
    }
  };

  const handleChangeComunas = async (newValue) => {
    setSelectedComunas(newValue);
    if (newValue.length > 0) {
      const agente = userInfo.iata_agentes === "SCL" ? "BODEGA SANTIAGO" : userInfo.name;
      const selectedIatas = newValue.map((comuna) => comuna.IATA);

      try {
        const ordenes = await buscarOrdenesComunas(selectedIatas, agente, userInfo.iata_agentes);

        if (ordenes && ordenes.length > 0) {
          setOrdenesDataTabla(ordenes);
        } else {
          toast.error("Comunas seleccionadas no poseen retiros.");
          setOrdenesDataTabla([]);
        }
      } catch (error) {
        console.error("Error al buscar ordenes:", error);
        toast.error("Error al realizar la búsqueda de órdenes.");
      }
    } else {
      setOrdenesDataTabla([]);
    }
  };

  const Cancelar = async () => {
    resetState();
  };

  const crearManifiesto = async () => {
    if (codigoChofer === idPeoneta) {
      toast.error("Chofer y peoneta no deben ser los mismos");
      return;
    }

    setLoading(true);

    const ods = ordenesDataTabla.map((orden) => orden.OD);
    const comunaNombres = selectedComunas.map((comuna) => comuna.NOMBRE).join(", ");

    try {
      const fecha = getFecha();

      const data = {
        FH_CREACION: fecha,
        FH_CIERRE: fecha,
        COD_USUARIO: id_user,
        PROCESO: "SALIDARUTARETIROS",
        ID_CLIENTE: 0,
        COD_CHOFER: codigoChofer,
        ESTADO: "CERRADO",
        COD_OPERADOR_ENTRADA: codigoChofer,
        COD_CHOFER_ENTRADA: codigoChofer,
        NOMBRE_CHOFER_ENTRADA: userInfo.name,
        SELLOS: "RETIROS",
        CANTIDAD_VALIJAS: 0,
        TOTAL_BULTOS: 1,
        TIPO_RUTA: "RETIROS",
        TIPO_MANIFIESTO: "SALIDARUTARETIROS",
        ORIGEN: userInfo.iata_agentes,
        DESTINO: userInfo.iata_agentes,
        NOTA: "",
        FH_MAIL: "",
        RUTA: comunaNombres,
        COD_PEONETA: idPeoneta,
        PATENTE: selectedPatente.label,
        AGENTE_ASIGNADO: userInfo.AGENTE_ASIGNADO,
      };

      const response = await insertManifiesto(data);

      for (const od of ods) {
        const datosParaEnviar = {
          OD: od,
          ID_MANIFIESTO: response,
          TIPO_MANIFIESTO: "SALIDARUTARETIROS",
          COD_USUARIO: id_user,
        };

        try {
          await insertPickingManifiestoMasivo(datosParaEnviar);
        } catch (error) {
          console.log("Fallo el envio");
        }
      }

      Swal.fire({
        title: "Manifiesto Creado",
        text: "N°: " + response,
        icon: "success",
      });
      try {
        resetState();
        setLoading(false);
        await downloadManifestPdfGlobal(response, "SALIDARUTARETIROS", "", selectedChofer.label, false);
      } catch (error) {
        showError("Error al descargar PDF!!!");
        return;
      }

      return response;
    } catch (error) {
      console.error("Error al crear el manifiesto:", error);
    }
  };

  const handleCrearManifiesto = async (newValue) => {
    try {
      if (!selectedChofer) {
        toast.error("Debe seleccionar un Chofer.");
        return;
      } else if (selectedComunas.length < 1) {
        console.log("Seleccione comunas");
        toast.error("Debe seleccionar las Comunas.");
        return;
      } else if (
        userInfo.tipo_usuario !== "AGENTE" &&
        userInfo.tipo_usuario !== "AGENTE_HUB" &&
        userInfo.tipo_usuario !== "AGENTE_ADMIN" &&
        !selectedPatente
      ) {
        toast.error("Debe seleccionar una patente antes de continuar!!!");
        return;
      }

      Swal.fire({
        title: "Seguro de Crear Manifiesto?",
        text: "Esta a punto de crear un nuevo Manifiesto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Crear!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          crearManifiesto();
        }
      });
      setLoading(false);
    } catch {}
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Autocomplete
              disableClearable
              id="combo-box-chofer"
              options={choferes}
              getOptionLabel={(option) => `${option.NOMBRE}`}
              isOptionEqualToValue={(option, value) => option.COD_CHOFER === value.COD_CHOFER}
              value={selectedChofer}
              onChange={(event, newValue) => {
                setSelectedChofer(newValue);
                handleChangeChofer(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Seleccione un Chofer" variant="outlined" fullWidth size="small" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Autocomplete
              //disableClearable
              value={selectedPeoneta}
              disablePortal
              id="size-small-outlined-multi"
              size="small"
              options={peonetas}
              onChange={handleChangePeoneta}
              sx={{ width: "100%" }}
              PaperComponent={({ children }) => (
                <Paper
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    "& li": { textAlign: "left" },
                  }}
                >
                  {children}
                </Paper>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Seleccione un peoneta" variant="outlined" fullWidth size="small" />
              )}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </Grid>
          {!["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? (
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <AutocompletePersonalizado
                selectedValue={selectedPatente}
                setSelectedValue={setSelectedPatente}
                options={patentes}
                onChange={(event, newValue) => handleChangePatente(event, newValue, setSelectedPatente)}
                label="Seleccione una patente"
              />
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            sm={12}
            md={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 6 : 4}
            lg={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 6 : 4}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={comunas}
              disableCloseOnSelect
              getOptionLabel={(option) => option.NOMBRE}
              isOptionEqualToValue={(option, value) => option.ID_IATA === value.ID_IATA}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} style={{ marginRight: 8 }} />
                  {option.NOMBRE}
                </li>
              )}
              value={selectedComunas}
              onChange={(event, newValue) => {
                handleChangeComunas(newValue);
                setSelectedComunas(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Seleccione la o las comunas." variant="outlined" fullWidth size="small" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Button
              className="boton1"
              size="medium"
              variant="contained"
              style={{ backgroundColor: "#041562" }}
              onClick={handleCrearManifiesto}
            >
              {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Button
              style={{ backgroundColor: "#DA251C" }}
              className="boton1"
              size="medium"
              variant="contained"
              onClick={Cancelar}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <Grid style={{ marginTop: "10px" }}>
            <TablaManifiestos rows={ordenesDataTabla} columns={columns} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ManifiestoComuna;
