import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Grid, TextField, Autocomplete, Typography, Paper, CircularProgress, Checkbox } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import useSound from "use-sound";
import sonido1 from "../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/AlertaMa.mp3";
import {
  getChoferesXId,
  insertPickingTemp,
  insertManifiesto,
  deletePickingTemp,
  insertPicking,
  buscarDatosPickingTempManifiestoRuta,
  //buscarComunasChoferManifiestoRuta,
  obtenerChoferesCondicional,
  downloadManifestPdfGlobal,
  getPatentes,
} from "../../../services/ManifiestosServices";
import { getOrdenIdReferencia } from "../../../services/ManifiestosServices";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import TablaManifiestos from "../../../components/OperacionesComponentes/componentesManifiestos/TablaManifiestos";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { toast } from "react-toastify";
import { AutocompletePersonalizado } from "../../../components/OperacionesComponentes/componentesVisuales/UtilesPersonalizados";

const ManifiestosRutaRef = () => {
  const inputRef = useRef(null);

  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const ciudadUser = userInfo ? userInfo.nombre_ciudad_agentes : null;
  const nombreUser = userInfo ? userInfo.name : null;
  const iataUser = userInfo ? userInfo.iata_agentes : null;
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");
  const [proceso, setProceso] = useState("");
  const [fechaActual, setfechaActual] = useState("");
  const [nota, setNota] = useState("");
  const [choferes, setChoferes] = useState([]);
  const [idChofer, setIdChofer] = useState("");
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [selectedPeoneta, setSelectedPeoneta] = useState(null);
  const [idPeoneta, setIdPeoneta] = useState("");
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [totalBultos, setTotalBultos] = useState(0);
  const [totalOrdenes, setTotalOrdenes] = useState(0);
  const [playsound] = useSound(sonido1);
  const [loadingEliminar, setLoadingEliminar] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [isbtnEnviar, setIsbtnEnviar] = useState(false);
  const [patentes, setPatentes] = useState([]);
  const [selectedPatente, setSelectedPatente] = useState(null);
  const [comunas, setComunas] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [selectedComunas, setSelectedComunas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setProceso("SALIDARUTA");
      try {
        const fechaActual = moment().format("YYYY-MM-DD");
        setfechaActual(fechaActual);

        const choferesData = await getChoferesXId(id_user);
        const choferesOptions = choferesData.map((choferes) => ({
          value: choferes.COD_CHOFER,
          label: choferes.NOMBRE,
        }));
        setChoferes(choferesOptions);
      } catch {}
    };
    fetchData();
  }, [id_user]);

  useEffect(() => {
    const Patentes = async () => {
      try {
        const patentesData = await getPatentes();
        const patentesOptions = patentesData.map((patentes) => ({
          value: patentes.ID,
          label: patentes.PATENTE,
        }));
        console.log(patentesOptions);
        setPatentes(patentesOptions);
      } catch {}
    };
    Patentes();
  }, []);

  useEffect(() => {
    const Clientes = async () => {
      try {
        const clientesData = [{ label: "NOVOFARMA SERVICE S.A.", value: 632 }];

        setSelectedCliente(clientesData[0].label);
        setIdCliente(clientesData[0].value);
        setClientes(clientesData);
      } catch {}
    };
    Clientes();
  }, []);

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: message,
    });
    playsound();
    inputRef.current.value = "";
  };

  const columns = [
    { field: "COD_BARRA", headerName: "COD. BARRA", width: 100 },
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE", headerName: "Cliente", width: 400 },
    { field: "BULTOS", headerName: "Bultos", width: 100 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 200 },
    { field: "NOMBRE_DESTINO", headerName: "Comuna", width: 200 },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon style={{ color: "#DA251C", cursor: "pointer" }} onClick={() => handleDelete(params.row.OD)} />
      ),
    },
  ];

  const handleDelete = async (orden) => {
    setLoadingEliminar(true);
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    });

    if (result.isConfirmed) {
      try {
        const response = await deletePickingTemp({
          OD: orden,
          COD_USUARIO: id_user,
          PROCESO: proceso,
        });

        if (response.message === "PickingTemp Eliminado con éxito") {
          const datos = {
            COD_USUARIO: id_user,
            COD_CHOFER: idChofer,
            PROCESO: proceso,
          };
          try {
            const resultados = await buscarDatosPickingTempManifiestoRuta(datos);

            let totalBultosTemp = 0;
            if (resultados && resultados.results) {
              resultados.results.forEach((orden) => {
                totalBultosTemp += orden.BULTOS;
              });
              setTotalOrdenes(resultados.results.length);
            }

            setTotalBultos(totalBultosTemp);

            if (resultados && Array.isArray(resultados.results)) {
              setOrdenesDataTabla(resultados.results);
            } else {
              setOrdenesDataTabla([]);
            }
          } catch (error) {
            console.error("Error al buscar datos de picking temp:", error);
            setOrdenesDataTabla([]);
          }

          Swal.fire("Eliminado!", "El registro ha sido eliminado.", "success");
        } else {
          Swal.fire("No se pudo eliminar", response.message, "error");
        }
      } catch (error) {
        console.error("Error al eliminar el picking temp:", error);
        Swal.fire("Error!", "No se pudo eliminar el registro debido a un error en el servidor.", "error");
      } finally {
        setLoadingEliminar(false);
      }
    }
    setLoadingEliminar(false);
  };

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      const options = {
        timeZone: "America/Santiago",
        hour12: false,
      };

      const fecha = fechaActual.toLocaleDateString("es-CL", options);

      const [dia, mes, año] = fecha.split("-");

      const nuevaFecha = `${año}-${mes}-${dia}`;

      const hora = fechaActual.toLocaleTimeString("es-CL", {
        ...options,
        hour12: false,
      });

      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;

      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null;
    }
  };

  const resetState = () => {
    setNota("");
    setSelectedComunas([]);
    inputRef.current.value = "";
    setOrdenesDataTabla([]);
    setTotalBultos(0);
    setIdChofer("");
    const fechaActual = moment().format("YYYY-MM-DD");
    setfechaActual(fechaActual);
    setSelectedChofer(null);
    setTotalOrdenes("");
    setLoading(false);
    setIsbtnEnviar(false);
    setSelectedPeoneta(null);
    setIdPeoneta("");
  };

  const handleChangeClientes = async (event, newValue) => {
    try {
      setSelectedCliente(newValue);
      setIdCliente(newValue.value);
    } catch {}
  };

  const handleIngresarOrden = async (event) => {
    await ordenIngresada(event);
  };

  const handleChangeChoferes = async (event, newValue) => {
    try {
      setSelectedChofer(newValue);
      const codi = newValue.value;
      if (codi !== null) {
        setIdChofer(newValue.value);

        const datos = {
          COD_USUARIO: id_user,
          COD_CHOFER: codi,
          PROCESO: proceso,
        };
        const dataPickingTemp = await buscarDatosPickingTempManifiestoRuta(datos);

        let totalBultosTemp = 0;
        if (dataPickingTemp && dataPickingTemp.results) {
          dataPickingTemp.results.forEach((orden) => {
            totalBultosTemp += orden.BULTOS;
          });
          setTotalOrdenes(dataPickingTemp.results.length);
        }

        setTotalBultos(totalBultosTemp);

        if (dataPickingTemp && dataPickingTemp.results) {
          setOrdenesDataTabla(dataPickingTemp.results);
        } else {
          setOrdenesDataTabla([]);
        }
      } else {
        alert("Seleccione un chofer");
      }
      const dataComunas = await obtenerChoferesCondicional(codi);
      setComunas(dataComunas);
    } catch (error) {
      console.error("Error al obtener datos del backend:", error);
    }
  };

  const handleChangePeoneta = (event, newValue) => {
    if (newValue === null) {
      setSelectedPeoneta(null);
      setIdPeoneta("");
    } else {
      setSelectedPeoneta(newValue);
      setIdPeoneta(newValue.value);
    }
  };

  const handleChangePatente = (event, newValue) => {
    if (newValue === null) {
      setSelectedPatente(null);
    } else {
      setSelectedPatente(newValue.label);
      console.log(newValue.label);
    }
  };

  const handleChangeNota = (event) => {
    setNota(event.target.value);
  };

  const ordenIngresada = useCallback(
    async (event) => {
      event.preventDefault();
      const ordenValue = inputRef.current.value.trim();

      try {
        if (!idCliente) {
          showError("Debe seleccionar un Cliente!!!");
          return;
        }
        if (!idChofer) {
          showError("Debe seleccionar un chofer!!!");
          return;
        }
        if (selectedComunas.length === 0) {
          showError("Debe Seleccionar una Comuna!!!");
          return;
        }
        if (idChofer === idPeoneta) {
          toast.error("Chofer y peoneta no deben ser los mismos");
          return;
        }
        const datos = {
          COD_CLIENTE: idCliente,
          COD_BARRA: ordenValue,
        };
        const dataOrden = await getOrdenIdReferencia(datos);

        if (!dataOrden || dataOrden.length === 0) {
          showError("No se encontro orden con el codigo entregado para el cliente seleccionado!!!");
          return;
        }

        if (!ordenValue) {
          Swal.fire({
            icon: "warning",
            title: "Orden vacía",
            text: "Debe ingresar un valor de orden.",
          });
          return;
        }

        const ordenExistente = ordenesDataTabla.some((orden) => orden.ID === dataOrden[0].ID);

        if (ordenExistente) {
          showError("Orden ya ingresada al manifiesto!!!");
          return;
        }

        if (
          (dataOrden[0].TIPO_ORDEN === "ENTREGA" && iataUser === dataOrden[0].IATA_PADRE_COMUNA_DESTINO) ||
          (dataOrden[0].TIPO_ORDEN === "RETIRO" && iataUser === dataOrden[0].IATA_PADRE_COMUNA_ORIGEN) ||
          (dataOrden[0].TIPO_ORDEN === "RETIRO" && iataUser === dataOrden[0].IATA_PADRE_COMUNA_DESTINO)
        ) {
          ingresarOrdenTabla(dataOrden);
          inputRef.current.value = "";
          return;
        } else {
          Swal.fire({
            title: "Comuna diferente",
            text: "La comuna de la orden no coincide con la ciudad del agente. ¿Deseas ingresarla igual?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              ingresarOrdenTabla(dataOrden);
              inputRef.current.value = "";
            } else {
              inputRef.current.value = "";
            }
          });
        }
      } catch (error) {
        console.error("Error al buscar los datos de la orden:", error);
      } finally {
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idCliente, idChofer, selectedComunas.length, ordenesDataTabla, iataUser, selectedPatente],
  );

  const ingresarOrdenTabla = async (dataOrden) => {
    try {
      if (dataOrden && dataOrden.length > 0) {
        try {
          await insertPickingTemp({
            OD: dataOrden[0].OD,
            COD_USUARIO: id_user,
            COD_CHOFER: idChofer,
            COMUNA: iataUser,
            PROCESO: proceso,
          });

          setOrdenesDataTabla([...ordenesDataTabla, dataOrden[0]]);
          setTotalBultos(totalBultos + dataOrden[0].BULTOS);
          setTotalOrdenes(totalOrdenes + 1);
        } catch (error) {
          console.error("Error:", error);
        }
        inputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const crearManifeisto = async () => {
    if (idChofer === idPeoneta) {
      toast.error("Chofer y peoneta no deben ser los mismos");
      return;
    }
    try {
      const nombresConcatenados = selectedComunas.map((obj) => obj.NOMBRE).join(" - ");
      const fecha = getFecha();

      const data = {
        FH_CREACION: fecha,
        FH_CIERRE: fecha,
        COD_USUARIO: id_user,
        PROCESO: proceso,
        ID_CLIENTE: idCliente,
        COD_CHOFER: idChofer,
        ESTADO: "CERRADO",
        COD_OPERADOR_ENTRADA: idChofer,
        COD_CHOFER_ENTRADA: idChofer,
        NOMBRE_CHOFER_ENTRADA: nombreUser,
        SELLOS: "",
        CANTIDAD_VALIJAS: 0,
        TOTAL_BULTOS: totalBultos,
        TIPO_RUTA: "TERRESTRE",
        TIPO_MANIFIESTO: proceso,
        ORIGEN: iataUser,
        DESTINO: iataUser,
        NOTA: nota,
        FH_MAIL: "",
        RUTA: nombresConcatenados,
        COD_PEONETA: idPeoneta,
      };

      const response = await insertManifiesto(data);

      const datosParaEnviar = {
        COD_USUARIO: id_user,
        TIPO_MANIFIESTO: proceso,
        DESTINO: iataUser,
        ID_MANIFIESTO: response,
      };

      try {
        await insertPicking(datosParaEnviar);
      } catch (error) {
        console.log("Fallo el envio");
      }
      return response;
    } catch (error) {
      console.error("Error al crear el manifiesto:", error);
    }
  };

  const handleCrearManifiesto = async () => {
    setLoading(true);
    setIsbtnEnviar(false);
    if (!idChofer) {
      showError("Debe seleccionar un chofer antes de continuar!!!");
      setLoading(false);
      return;
    } else if (selectedComunas.length === 0) {
      showError("Debe seleccionar una comuna antes de continuar!!!");
      setLoading(false);
      return;
    } else if (ordenesDataTabla.length === 0) {
      showError("Manifiestos debe contener una orden!!!");
      setLoading(false);
      return;
    } else {
      Swal.fire({
        title: "Seguro de Crear Manifiesto?",
        text: "Esta a punto de crear un nuevo Manifiesto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Crear!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const numeroManifiesto = await crearManifeisto();
          Swal.fire({
            title: "Manifiesto Creado",
            text: "N°: " + numeroManifiesto,
            icon: "success",
          });
          try {
            await downloadManifestPdfGlobal(numeroManifiesto, proceso, "", selectedChofer.label, true);
          } catch (error) {
            showError("Error al descargar PDF!!!");
            return;
          }
          resetState();
        }
        setLoading(false);
      });
    }
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Autocomplete
                disabled
                value={selectedCliente}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={clientes}
                onChange={handleChangeClientes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="CLIENTE" />}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                name="FH_ACTUAL"
                label="Fecha"
                sx={{ width: "100%" }}
                value={fechaActual}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                label="ORIGEN MANIFIESTO"
                value={ciudadUser}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 4 : 3}
              lg={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 4 : 3}
            >
              <Autocomplete
                disableClearable
                value={selectedChofer}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={choferes}
                onChange={handleChangeChoferes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="CHOFER" />}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 4 : 3}
              lg={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 4 : 3}
            >
              <Autocomplete
                value={selectedPeoneta}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={choferes}
                onChange={handleChangePeoneta}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="PEONETA" />}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>
            {!["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? (
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <AutocompletePersonalizado
                  selectedValue={selectedPatente}
                  setSelectedValue={setSelectedPatente}
                  options={patentes}
                  onChange={(event, newValue) => handleChangePatente(event, newValue, setSelectedPatente)}
                  label="Seleccione una patente"
                />
              </Grid>
            ) : null}

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={comunas}
                disableCloseOnSelect
                getOptionLabel={(option) => option.NOMBRE}
                isOptionEqualToValue={(option, value) => option.ID_IATA === value.ID_IATA}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} style={{ marginRight: 8 }} />
                    {option.NOMBRE}
                  </li>
                )}
                value={selectedComunas}
                onChange={(event, newValue) => {
                  setSelectedComunas(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione una comuna" variant="outlined" fullWidth size="small" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                size="small"
                id="outlined-size-small"
                fullWidth
                label="Nota"
                multiline
                rows={1}
                name="message"
                value={nota}
                onChange={handleChangeNota}
                required
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}></Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} justifyContent="flex-end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleCrearManifiesto}
                disabled={isbtnEnviar}
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper style={{ padding: "10px", marginTop: "10px" }}>
        <form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Ingrese Codigo Barra"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    ordenIngresada(e);
                  }
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleIngresarOrden}
              >
                INGRESAR
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                ORDENES: {totalOrdenes}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                BULTOS: {totalBultos}
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Grid style={{ marginTop: "10px" }}>
              <TablaManifiestos rows={ordenesDataTabla} columns={columns} loading={loadingEliminar} />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
export default ManifiestosRutaRef;
