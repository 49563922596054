import React, { useState, memo, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Paper,
  MenuItem,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomDate from "../../utils/CustomDate";
import CustomAutocomplete from "../../utils/CustomAutocomplete";
import {
  obtenerClientesControlSac,
  obtenerComunas,
  descargarArchivo,
  /*   obtenerEstadisticas, */
  iniciarDescargaUnica,
} from "../../services/ConsultaKpisServices";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import MostrarTablaMultipleResumen from "../../components/ReportesComponentes/componenteKpis/MostrarTablaMultipleResumen";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const Consultakpis = () => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [comunaOrigen, setComunaOrigen] = useState({ IATA: "todas", nombre: "Todas" });
  const [comunaDestino, setComunaDestino] = useState({ IATA: "todas", nombre: "Todas" });
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [tipoOrden, setTipoOrden] = useState("todas");
  /*   const [isLoadingStats, setIsLoadingStats] = useState(false); */
  const [loading, setLoading] = useState({ tipoReporte: null, tipoArchivo: null });
  const [stats, setStats] = useState(null);
  const [isStatsFetched, setIsStatsFetched] = useState(false);

  const resetStats = () => {
    setStats(null);
    setIsStatsFetched(false);
  };

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await obtenerClientesControlSac();
        setClientes(response);
      } catch (error) {
        console.error("Error al obtener la lista de clientes:", error);
      }
    };

    const cargarComunas = async () => {
      try {
        const comunasObtenidas = await obtenerComunas();
        setComunas(comunasObtenidas);
      } catch (error) {
        console.error("Error al obtener comunas:", error);
      }
    };

    fetchClientes();
    cargarComunas();
  }, []);

  const formatFechaToYYYYMMDD = (date) => {
    return date.toISOString().split("T")[0];
  };

  const validarCampos = () => {
    if (!selectedClient) {
      toast.error("Debes seleccionar un cliente.");
      return false;
    }
    if (!comunaOrigen) {
      toast.error("Debes seleccionar una comuna de origen.");
      return false;
    }
    if (!comunaDestino) {
      toast.error("Debes seleccionar una comuna de destino.");
      return false;
    }
    if (!fechaDesde) {
      toast.error("Debes seleccionar la fecha desde.");
      return false;
    }
    if (!fechaHasta) {
      toast.error("Debes seleccionar la fecha hasta.");
      return false;
    }
    if (fechaDesde > fechaHasta) {
      toast.error("La fecha 'Desde' no puede ser posterior a la fecha 'Hasta'.");
      return false;
    }
    const diffTime = Math.abs(fechaHasta - fechaDesde);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 93) {
      toast.error("El rango entre las fechas debe ser de máximo 93 días.");
      return false;
    }
    return true;
  };

  /* const verEstadisticas = async () => {
    if (!validarCampos()) return;

    setIsLoadingStats(true);
    setIsStatsFetched(false);

    const data = {
      clienteId: selectedClient?.id,
      tipoOrden,
      comunaOrigen: comunaOrigen?.IATA,
      comunaDestino: comunaDestino?.IATA,
      fechaDesde: fechaDesde ? formatFechaToYYYYMMDD(fechaDesde) : null,
      fechaHasta: fechaHasta ? formatFechaToYYYYMMDD(fechaHasta) : null,
    };

    try {
      const response = await obtenerEstadisticas(data);
      setStats(response);
      setIsStatsFetched(true);
    } catch (error) {
      console.error("Error obteniendo estadísticas:", error);
      toast.error("Error al obtener estadísticas");
    } finally {
      setIsLoadingStats(false);
    }
  };
 */
  const descargarReporte = async (tipoArchivo, tipoReporte) => {
    if (!validarCampos()) return;

    // Establecer el estado de carga
    setLoading({ tipoReporte, tipoArchivo });

    const data = {
      clienteId: selectedClient?.id,
      tipoOrden,
      comunaOrigen: comunaOrigen?.IATA,
      comunaDestino: comunaDestino?.IATA,
      fechaDesde: fechaDesde ? formatFechaToYYYYMMDD(fechaDesde) : null,
      fechaHasta: fechaHasta ? formatFechaToYYYYMMDD(fechaHasta) : null,
      tipoReporte,
      tipoArchivo,
    };

    try {
      const { url } = await iniciarDescargaUnica(data);
      if (url) {
        const fileUrl = descargarArchivo(url, `Reporte-${tipoArchivo}`);
        toast.info(
          <div>
            <p>
              Haz clic{" "}
              <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                aquí
              </a>{" "}
              para descargar el archivo.
            </p>
          </div>,
          { autoClose: false },
        );
      } else {
        toast.error(`Error al obtener la URL del archivo ${tipoArchivo.toUpperCase()}`);
      }
    } catch (error) {
      console.error(`Error al descargar ${tipoArchivo}:`, error);
      toast.error(`Error al descargar ${tipoArchivo.toUpperCase()}`);
    } finally {
      // Restablecer el estado de carga
      setLoading({ tipoReporte: null, tipoArchivo: null });
    }
  };

  return (
    <Container maxWidth="xl">
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <CustomAutocomplete
              label="Clientes"
              options={[
                { id: "todos", nombre: "Todos" },
                ...clientes.map((cliente) => ({
                  id: cliente.ID,
                  nombre: `${cliente.NOMBRE} (ID: ${cliente.ID})`,
                })),
              ]}
              getOptionLabel={(option) => option.nombre}
              value={selectedClient}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                setSelectedClient(newValue);
                resetStats();
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CustomAutocomplete
              label="Ciudad Origen"
              options={[
                { IATA: "todas", nombre: "Todas" },
                ...comunas.map((c) => ({
                  IATA: c.IATA,
                  nombre: `${c.NOMBRE} (${c.IATA})`,
                })),
              ]}
              getOptionLabel={(option) => option.nombre}
              value={comunaOrigen}
              onChange={(event, newValue) => {
                setComunaOrigen(newValue);
                resetStats();
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CustomAutocomplete
              label="Ciudad Destino"
              options={[
                { IATA: "todas", nombre: "Todas" },
                ...comunas.map((c) => ({
                  IATA: c.IATA,
                  nombre: `${c.NOMBRE} (${c.IATA})`,
                })),
              ]}
              getOptionLabel={(option) => option.nombre}
              value={comunaDestino}
              onChange={(event, newValue) => {
                setComunaDestino(newValue);
                resetStats();
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              select
              label="Tipo de Orden"
              value={tipoOrden}
              onChange={(e) => {
                setTipoOrden(e.target.value);
                resetStats();
              }}
              fullWidth
              margin="dense"
              variant="outlined"
              size="small"
            >
              <MenuItem value="ENTREGA">Entregas</MenuItem>
              <MenuItem value="RETIRO">Retiros</MenuItem>
              <MenuItem value="todas">Todas</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CustomDate
              label="Fecha Desde"
              value={fechaDesde}
              onChange={(newValue) => {
                setFechaDesde(newValue);
                resetStats();
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CustomDate
              label="Fecha Hasta"
              value={fechaHasta}
              onChange={(newValue) => {
                setFechaHasta(newValue);
                resetStats();
              }}
            />
          </Grid>
        </Grid>

        <Divider style={{ margin: "10px 0" }} />

        <Grid container spacing={2}>
          {/*  <Grid item xs={12} sm={6} md={3}>
            <Button
              onClick={verEstadisticas}
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoadingStats}
              sx={{
                backgroundColor: "#041562",
                color: "#FFFFFF",
                "&:hover": { backgroundColor: "#00003f" },
              }}
            >
              {isLoadingStats ? <CircularProgress size={24} /> : "Ver Estadísticas"}
            </Button>
          </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <Button
              onClick={() => descargarReporte("csv", "interno")}
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<FileDownloadIcon />}
              disabled={loading.tipoReporte === "interno" && loading.tipoArchivo === "csv"}
              sx={{
                backgroundColor: "#2ecc71",
                color: "#FFFFFF",
                "&:hover": { backgroundColor: "#27ae60" },
              }}
            >
              {loading.tipoReporte === "interno" && loading.tipoArchivo === "csv" ? (
                <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
              ) : (
                "Reporte Interno"
              )}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              onClick={() => descargarReporte("pdf", "interno")}
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<PictureAsPdfIcon />}
              disabled={loading.tipoReporte === "interno" && loading.tipoArchivo === "pdf"}
              sx={{
                backgroundColor: "#e74c3c",
                color: "#FFFFFF",
                "&:hover": { backgroundColor: "#c0392b" },
              }}
            >
              {loading.tipoReporte === "interno" && loading.tipoArchivo === "pdf" ? (
                <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
              ) : (
                "Reporte Interno"
              )}
            </Button>
          </Grid>
          {/*  </Grid> */}

          {/*   <Divider style={{ margin: "10px 0" }} /> */}

          {/* <Grid container spacing={2}> */}
          <Grid item xs={12} sm={6} md={3}>
            <Button
              onClick={() => descargarReporte("csv", "cliente")}
              variant="contained"
              fullWidth
              disabled={loading.tipoReporte === "cliente" && loading.tipoArchivo === "csv"}
              startIcon={<FileDownloadIcon />}
              sx={{
                backgroundColor: "#28a745",
                color: "#FFFFFF",
                "&:hover": { backgroundColor: "#218838" },
              }}
            >
              {loading.tipoReporte === "cliente" && loading.tipoArchivo === "csv" ? (
                <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
              ) : (
                "Reporte Cliente"
              )}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              onClick={() => descargarReporte("pdf", "cliente")}
              variant="contained"
              fullWidth
              disabled={loading.tipoReporte === "cliente" && loading.tipoArchivo === "pdf"}
              startIcon={<PictureAsPdfIcon />}
              sx={{
                backgroundColor: "#c82333",
                color: "#FFFFFF",
                "&:hover": { backgroundColor: "#bd2130" },
              }}
            >
              {loading.tipoReporte === "cliente" && loading.tipoArchivo === "pdf" ? (
                <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
              ) : (
                "Reporte Cliente"
              )}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {isStatsFetched && stats && (
        <Paper style={{ padding: "20px", marginBottom: "20px", position: "relative" }}>
          <Typography variant="h6" gutterBottom>
            {selectedClient?.nombre || "Dashboard KPI/SLA"}
          </Typography>
          {stats.type === "multi" && <MostrarTablaMultipleResumen stats={stats} />}
          {stats.type === "single" && <MostrarGraficoSingleResumen stats={stats} />}
        </Paper>
      )}

      {(loading.tipoReporte/*  || isLoadingStats */) && (
        <Typography variant="body2" color="red" align="center" sx={{ marginTop: 2 }}>
          Generando reporte, espera por favor...
        </Typography>
      )}
    </Container>
  );
};

/* Muestra un gráfico comparando SLA/KPI en Entregas, Retiros y Global para un cliente */
function MostrarGraficoSingleResumen({ stats }) {
  const slaEntregasPct = parseFloat(stats.slaEntregasPct) || 0;
  const slaRetirosPct = parseFloat(stats.slaRetirosPct) || 0;
  const kpiEntregasPct = parseFloat(stats.kpiEntregasPct) || 0;
  const kpiRetirosPct = parseFloat(stats.kpiRetirosPct) || 0;

  const dataSLA = [
    { name: "Entregas", value: slaEntregasPct },
    { name: "Retiros", value: slaRetirosPct },
  ];

  const dataKPI = [
    { name: "Entregas", value: kpiEntregasPct },
    { name: "Retiros", value: kpiRetirosPct },
  ];

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>
          SLA (Entregas y Retiros)
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={dataSLA}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="rgba(54, 162, 235, 0.6)" name="SLA (%)" />
          </BarChart>
        </ResponsiveContainer>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>
          KPI (Entregas y Retiros)
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={dataKPI}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="rgba(255, 99, 132, 0.6)" name="KPI (%)" />
          </BarChart>
        </ResponsiveContainer>
      </Grid>

      <Grid item xs={12} style={{ marginTop: "20px", textAlign: "center" }}>
        <Typography variant="body1">
          <strong>Total Órdenes:</strong> {stats.totalOrdenes}
        </Typography>
        <Typography variant="body1">
          <strong>Entregas:</strong> {stats.totalEntregas}
        </Typography>
        <Typography variant="body1">
          <strong>Retiros:</strong> {stats.totalRetiros}
        </Typography>
        <Typography variant="body1">
          <strong>SLA Global:</strong> {stats.slaGlobalPct} %
        </Typography>
        <Typography variant="body1">
          <strong>KPI Global:</strong> {stats.kpiGlobalPct} %
        </Typography>
        <Typography variant="body1">
          <strong>Rango de Fechas:</strong> {stats.fechaDesde} - {stats.fechaHasta}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default memo(Consultakpis);
