import React, { useState, useEffect } from "react";
import { Paper, Grid, TextField, Button, Autocomplete } from "@mui/material";
import { toast } from "react-toastify";
import {
  getManifiesto,
  getChoferesXId,
  updateChoferManifiestoRuta,
  deletePicking,
  deleteManifiesto,
  getPatentes,
} from "../../../services/ManifiestosServices";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import TablaGestorManifiesto from "../../../components/OperacionesComponentes/componentesManifiestos/TablaGestorManifiesto";
import { AutocompletePersonalizado } from "../../../components/OperacionesComponentes/componentesVisuales/UtilesPersonalizados";
import { handleChangePatente } from "../../../components/OperacionesComponentes/componentesManifiestoRuta/EventHandler";

function GestorManifiestoRuta() {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const [manifiestoId, setManifiestoId] = useState("");
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [selectedPeoneta, setSelectedPeoneta] = useState(null);
  const [choferes, setChoferes] = useState([]);
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [patentes, setPatentes] = useState([]);
  const [selectedPatente, setSelectedPatente] = useState("");

  useEffect(() => {
    const Patentes = async () => {
      try {
        const patentesData = await getPatentes();
        const patentesOptions = patentesData.map((patentes) => ({
          value: patentes.ID,
          label: patentes.PATENTE,
        }));
        console.log(patentesOptions);
        setPatentes(patentesOptions);
      } catch {}
    };
    Patentes();
  }, []);

  const handleChange = (event) => {
    setManifiestoId(event.target.value);
  };

  const handleBuscar = async () => {
    if (!manifiestoId.trim()) {
      toast.error("Debe ingresar un número de manifiesto");
      return;
    }
    const datos = {
      ID_MANIFIESTO: manifiestoId,
      ORIGEN: userInfo.iata_agentes,
    };

    const data = await getManifiesto(datos);
    if (data.length > 0) {
      if (userInfo.iata_agentes !== data[0].ORIGEN) {
        toast.error("Manifiesto no pertenece a comuna");
        return;
      }

      if (
        data[0].PROCESO !== "SALIDARUTA" &&
        data[0].PROCESO !== "SALIDARUTARETIROS" &&
        data[0].PROCESO !== "RETORNOCLIENTES"
      ) {
        toast.error("Manifiesto es de otro tipo");
        return;
      }

      setOrdenesDataTabla(data);
      const choferesData = await getChoferesXId(id_user);

      const choferesOptions = choferesData.map((choferes) => ({
        value: choferes.COD_CHOFER,
        label: choferes.NOMBRE,
      }));
      setChoferes(choferesOptions);

      const choferOptions = {
        value: data[0].COD_CHOFER,
        label: data[0].NOMBRE_CHOFER,
      };

      setSelectedChofer(choferOptions);

      if (data[0].COD_PEONETA !== null) {
        const peonetaOptions = {
          value: data[0].COD_PEONETA,
          label: data[0].NOMBRE_PEONETA,
        };

        setSelectedPeoneta(peonetaOptions);
      }

      if (data[0].PATENTE !== null) {
        setSelectedPatente(data[0].PATENTE);
      }
    } else {
      toast.error("Manifiesto no existe");
      return;
    }
  };

  const handleChangeChoferes = async (event, newValue) => {
    setSelectedChofer(newValue);
  };
  const handleChangePeonetas = async (event, newValue) => {
    if (newValue === null) {
      setSelectedPeoneta(null);
    } else {
      setSelectedPeoneta(newValue);
    }
  };

  const handleEditar = async () => {
    if (!manifiestoId.trim()) {
      toast.error("Debe ingresar un número de manifiesto");
      return;
    }

    if (!selectedChofer) {
      toast.error("Debe Seleccionar un chofer");
      return;
    }

    if (selectedPeoneta !== null) {
      if (selectedPeoneta.label === selectedChofer.label) {
        toast.error("Chofer y Peoneta deben de ser distinto");
        return;
      }
    }

    Swal.fire({
      title: "Seguro de Modificar?",
      text: "Esta de editar un Manifiesto!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Crear!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let peoneta;
        if (selectedPeoneta === null) {
          peoneta = null;
        } else {
          peoneta = selectedPeoneta.value;
        }

        const datos = {
          COD_CHOFER: selectedChofer.value,
          COD_PEONETA: peoneta,
          ID: manifiestoId,
          PATENTE: selectedPatente.label,
        };
        const response = await updateChoferManifiestoRuta(datos);
        if (response.success === true) {
          Swal.fire({
            title: "Manifiesto modificado",
            icon: "success",
          });
        } else {
        }
      }
    });
  };

  const handleEliminar = async () => {
    if (selectedItems.size === 0) {
      toast.error("Debe seleccionar al menos una orden para eliminar");
      return;
    }
    const ordenesArray = Array.from(selectedItems);

    const datos = {
      ordenes: ordenesArray,
      ID_MANIFIESTO: manifiestoId,
      usuario: id_user,
      PROCESO: "SALIDARUTA",
    };

    Swal.fire({
      title: "Seguro de Eliminar?",
      text: "Está a punto de eliminar las órdenes seleccionadas del manifiesto!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Eliminar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deletePicking(datos);
        if (response.success) {
          Swal.fire({
            title: "Órdenes eliminadas",
            icon: "success",
          });
          const datos = {
            ID_MANIFIESTO: manifiestoId,
            //PROCESO: "SALIDARUTA",
            ORIGEN: userInfo.iata_agentes,
          };
          const data = await getManifiesto(datos);
          if (data.length > 0) {
            setOrdenesDataTabla(data);
            console.log(data);
          } else {
            await deleteManifiesto(datos);
            setOrdenesDataTabla([]);
            setManifiestoId("");
            setSelectedChofer(null);
            setSelectedPeoneta(null);
            setSelectedPatente(null);
          }
          setSelectedItems(new Set());
        } else {
          toast.error("No se pudieron eliminar las órdenes");
        }
      }
    });
  };

  return (
    <div>
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <TextField
              label="Número de Manifiesto"
              variant="outlined"
              value={manifiestoId}
              type="search"
              size="small"
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button
              variant="contained"
              onClick={handleBuscar}
              sx={{
                backgroundColor: "#041562",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#030e4f",
                },
              }}
            >
              BUSCAR
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 3 : 2}
            lg={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 3 : 2}
          >
            <Autocomplete
              disableClearable
              value={selectedChofer}
              disablePortal
              id="size-small-outlined-multi"
              size="small"
              options={choferes}
              onChange={handleChangeChoferes}
              sx={{ width: "100%" }}
              PaperComponent={({ children }) => (
                <Paper
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    "& li": { textAlign: "left" },
                  }}
                >
                  {children}
                </Paper>
              )}
              renderInput={(params) => <TextField {...params} label="CHOFER" />}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 3 : 2}
            lg={["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? 3 : 2}
          >
            <Autocomplete
              value={selectedPeoneta}
              disablePortal
              id="size-small-outlined-multi"
              size="small"
              options={choferes}
              onChange={handleChangePeonetas}
              sx={{ width: "100%" }}
              PaperComponent={({ children }) => (
                <Paper
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    "& li": { textAlign: "left" },
                  }}
                >
                  {children}
                </Paper>
              )}
              renderInput={(params) => <TextField {...params} label="PEONETA" />}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </Grid>
          {!["AGENTE", "AGENTE_HUB", "AGENTE_ADMIN"].includes(userInfo.tipo_usuario) ? (
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <AutocompletePersonalizado
                selectedValue={selectedPatente}
                setSelectedValue={setSelectedPatente}
                options={patentes}
                onChange={(event, newValue) => handleChangePatente(event, newValue, setSelectedPatente)}
                label="PATENTE"
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button
              variant="contained"
              onClick={handleEditar}
              sx={{
                backgroundColor: "#041562",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#030e4f",
                },
              }}
            >
              ACTUALIZAR
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              onClick={handleEliminar}
              sx={{
                backgroundColor: "#DA251C",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#030e4f",
                },
                mb: 2,
              }}
            >
              ELIMINAR ORDENES SELECCIONADAS DE MANIFIESTO
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TablaGestorManifiesto ordenesDataTabla={ordenesDataTabla} setSelectedItems={setSelectedItems} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default GestorManifiestoRuta;
