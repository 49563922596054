import { useState, useEffect } from "react";
import React from "react";
import { getManifiesto } from "../../../services/ManifiestosServices";
import { Paper, Grid, TextField, Button} from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TablaManifiestos from "../../OperacionesComponentes/componentesManifiestos/TablaManifiestos";
import { createCertificacion } from "../../../services/EventosManualServices";
import moment from "moment";
import Swal from "sweetalert2";

const InsertEventoOdManifiesto = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const [manifiestoId, setManifiestoId] = useState("");
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [fechaActual, setFechaActual] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fechaFormateada = moment().format("DD/MM/YYYY HH:mm:ss");
    setFechaActual(fechaFormateada);
  }, []);

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "CLIENTE", headerName: "Cliente", width: 350 },
    { field: "BULTOS", headerName: "Bultos", width: 70 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 100 },
    { field: "COMUNA_DESTINO", headerName: "Comuna", width: 200 },
  ];

  const handleChange = (event) => {
    setManifiestoId(event.target.value);
  };

  const handleBuscar = async () => {
    if (!manifiestoId.trim()) {
      toast.error("Debe ingresar un número de manifiesto");
      return;
    }

    try {
      const datos = { ID_MANIFIESTO: manifiestoId };
      const data = await getManifiesto(datos);
      console.log(data);

      if (data.length > 0) {
        const esRetornoClientes = data.some((orden) => orden.PROCESO === "RETORNOCLIENTES");

        if (esRetornoClientes) {
          setOrdenesDataTabla(data);
        } else {
          toast.error("El manifiesto no pertenece al proceso RETORNOCLIENTES");
          setOrdenesDataTabla([]);
        }
      } else {
        toast.error("Manifiesto no existe");
        setOrdenesDataTabla([]);
      }
    } catch (error) {
      console.error("Error al buscar manifiesto:", error);
      toast.error("Error al buscar manifiesto");
    }
  };

  const handleCrearEvento = async () => {
    if (ordenesDataTabla.length === 0) {
      toast.error("Debe buscar un manifiesto");
      return;
    }
    setLoading(true);
    
    const confirmacion = await Swal.fire({
      title: "¿Estás seguro?",
      text: "Se crearán eventos para todas las órdenes listadas.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, crear eventos",
      cancelButtonText: "Cancelar",
    });

    if (!confirmacion.isConfirmed) {
      setLoading(false);
      return; 
    }

    try {
      for (const orden of ordenesDataTabla) {
        const certificacionData = {
          OD: orden.OD,
          OD_PAPEL: orden.OD,
          COD_CHOFER: null,
          RUT: "Sin rut",
          NOMBRE: "Sin nombre",
          NOTA: "",
          LAT_TERRENO: "0",
          LONG_TERRENO: "0",
          FH_GESTION: fechaActual,
          COD_ESTADO: 53,
          USUARIO: userInfo.id,
          TN: "0",
          ORIGEN_CERTIFICACION: "CREACION EVENTO MANUAL PORTAL NUEVO",
        };

        await createCertificacion(certificacionData);
      }

      toast.success("Eventos creados correctamente");
      setOrdenesDataTabla([]);
      setManifiestoId("");
      setLoading(false);
    } catch (error) {
      console.error("Error al crear eventos:", error);
      toast.error("Error al crear eventos");
    }
  };

  return (
    <div>
        <Paper style={{ padding: "10px", marginBottom: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                label="Número de Manifiesto"
                variant="outlined"
                value={manifiestoId}
                type="search"
                size="small"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Button
                disabled={loading}
                variant="contained"
                onClick={handleBuscar}
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#030e4f",
                  },
                }}
              >
                BUSCAR
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Button
                disabled={loading}
                variant="contained"
                onClick={handleCrearEvento}
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#DA251C",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#030e4f",
                  },
                }}
              >
                CREAR EVENTO
              </Button>
            </Grid>
          </Grid>
        </Paper>
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TablaManifiestos rows={ordenesDataTabla} columns={columns} loading={loading} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default InsertEventoOdManifiesto;
