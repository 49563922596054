import React, {useState } from "react";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import RetirosChoferes from "../../components/DespachoComponentes/componentesControlChoferes/RetirosChoferes";

const ControlChoferes = () => {
  const [selectedModule, setSelectedModule] = useState("");
  const userInfo = useSelector((state) => state.user.userInfo);

  const renderModule = () => {
    if (userInfo.tipo_usuario === "SUPERVISOR" && selectedModule !== "insercionEventoOdManifiesto") {
      return <Typography variant="h6">Seleccione un módulo para mostrar</Typography>;
    }

    switch (selectedModule) {
      case "RetirosChoferes":
        return <RetirosChoferes />;
      default:
        return <Typography variant="h6">Seleccione un módulo para mostrar</Typography>;
    }
  };

  return (
    <Box p={3}>
      <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
        {/* Todos los usuarios (incluyendo SUPERVISOR) ven este módulo */}
        <Button
          onClick={() => setSelectedModule("RetirosChoferes")}
          sx={{
            backgroundColor: selectedModule === "RetirosChoferes" ? "#041562" : undefined,
            color: selectedModule === "RetirosChoferes" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "RetirosChoferes" ? "#DA251C" : undefined,
            },
          }}
        >
          Retiros Choferes
        </Button>
      </ButtonGroup>

      <Box mt={3}>{renderModule()}</Box>
    </Box>
  );
};

export default ControlChoferes;
