import React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import {
  ExpandLess,
  ExpandMore,
  Assignment,
  FindInPage,
  ReceiptLong,
  LocalShipping,
  AssignmentTurnedIn,
  FilePresent,

} from "@mui/icons-material";
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
function MenuSac({ isDrawerOpen, openSubMenu, handleClick, isVisible, hasVisibleSubmenus, location }) {
  return (
    hasVisibleSubmenus([
      "gestorEventos",
      "correccionOrden",
      "RetirosSac",
      "cedibles",
      "controlCedibles",
      "RendicionCedibles",
      "ListadoClientes",
      "Listados",
    ]) && (
      <>
      <ListItemButton onClick={() => handleClick("despacho")}>
      <ListItemIcon sx={{ color: "white" }}>
      <ReceiptLong />
      </ListItemIcon>
      {isDrawerOpen && <ListItemText primary="SAC" sx={{ color: "white" }} />}
      {isDrawerOpen &&
          (openSubMenu === "despacho" ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          ))}
      </ListItemButton>

      <Collapse in={openSubMenu === "despacho"} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
      {isVisible("Eventos") && (
        <ListItemButton
        component={Link}
        to="/Eventos"
        sx={{
          bgcolor: location.pathname === "/Eventos" ? "white" : "transparent",
            color: location.pathname === "/Eventos" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/Eventos" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/Eventos" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/Eventos" ? "10px" : "0px",
            margin: location.pathname === "/Eventos" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <Assignment />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Gestion de eventos" />}
        </ListItemButton>
      )}
      {isVisible("correccionOrden") && (
        <ListItemButton
        component={Link}
        to="/correccionOrden"
        sx={{
          bgcolor: location.pathname === "/correccionOrden" ? "white" : "transparent",
            color: location.pathname === "/correccionOrden" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/correccionOrden" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/correccionOrden" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/correccionOrden" ? "10px" : "0px",
            margin: location.pathname === "/correccionOrden" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <FindInPage />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Corrección de orden" />}
        </ListItemButton>
      )}

      {isVisible("cedibles") && (
        <ListItemButton
        component={Link}
        to="/cedibles"
        sx={{
          bgcolor: location.pathname === "/cedibles" ? "white" : "transparent",
            color: location.pathname === "/cedibles" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/cedibles" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/cedibles" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/cedibles" ? "10px" : "0px",
            margin: location.pathname === "/cedibles" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <LocalShipping />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Carga de cedibles" />}
        </ListItemButton>
      )}

      {isVisible("controlCedibles") && (
        <ListItemButton
        component={Link}
        to="/controlCedibles"
        sx={{
          bgcolor: location.pathname === "/controlCedibles" ? "white" : "transparent",
            color: location.pathname === "/controlCedibles" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/controlCedibles" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/controlCedibles" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/controlCedibles" ? "10px" : "0px",
            margin: location.pathname === "/controlCedibles" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <AssignmentTurnedIn />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Control de cedibles" />}
        </ListItemButton>
      )}
      {isVisible("RendicionCediblesViejo") && (
        <ListItemButton
        component={Link}
        to="/RendicionCediblesViejo"
        sx={{
          bgcolor: location.pathname === "/RendicionCediblesViejo" ? "white" : "transparent",
            color: location.pathname === "/RendicionCediblesViejo" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/RendicionCediblesViejo" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/RendicionCediblesViejo" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/RendicionCediblesViejo" ? "10px" : "0px",
            margin: location.pathname === "/RendicionCediblesViejo" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <FilePresent />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Rendicion de cedibles antiguo" />}
        </ListItemButton>
      )}
      {isVisible("RendicionCedibles") && (
        <ListItemButton
        component={Link}
        to="/RendicionCedibles"
        sx={{
          bgcolor: location.pathname === "/RendicionCedibles" ? "white" : "transparent",
            color: location.pathname === "/RendicionCedibles" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/RendicionCedibles" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/RendicionCedibles" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/RendicionCedibles" ? "10px" : "0px",
            margin: location.pathname === "/RendicionCedibles" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <FilePresent />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Rendicion de cedibles" />}
        </ListItemButton>
      )}
      {isVisible("Listados") && (
        <ListItemButton
        component={Link}
        to="/Listados"
        sx={{
          bgcolor: location.pathname === "/Listados" ? "white" : "transparent",
            color: location.pathname === "/Listados" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/Listados" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/Listados" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/Listados" ? "10px" : "0px",
            margin: location.pathname === "/Listados" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <RecentActorsIcon />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Listados" />}
        </ListItemButton>
      )}
      {isVisible("RetirosAppStgo") && (
        <ListItemButton
        component={Link}
        to="/RetirosAppStgo"
        sx={{
          bgcolor: location.pathname === "/RetirosAppStgo" ? "white" : "transparent",
            color: location.pathname === "/RetirosAppStgo" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/RetirosAppStgo" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/RetirosAppStgo" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/RetirosAppStgo" ? "10px" : "0px",
            margin: location.pathname === "/RetirosAppStgo" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <ArrowRightAltIcon />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Carga de retiros APP Santiago" />}
        </ListItemButton>
      )}
      {isVisible("RetirosAppInter") && (
        <ListItemButton
        component={Link}
        to="/RetirosAppInter"
        sx={{
          bgcolor: location.pathname === "/RetirosAppInter" ? "white" : "transparent",
            color: location.pathname === "/RetirosAppInter" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/RetiroRetirosAppIntersSac" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/RetirosAppInter" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/RetirosAppInter" ? "10px" : "0px",
            margin: location.pathname === "/RetirosAppInter" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <SyncAltIcon />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Carga de retiros APP Inter-Region" />}
        </ListItemButton>
      )}
      {isVisible("RetirosSantiago") && (
        <ListItemButton
        component={Link}
        to="/RetirosSantiago"
        sx={{
          bgcolor: location.pathname === "/RetirosSantiago" ? "white" : "transparent",
            color: location.pathname === "/RetirosSantiago" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/RetirosSantiago" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/RetirosSantiago" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/RetirosSantiago" ? "10px" : "0px",
            margin: location.pathname === "/RetirosSantiago" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <CallMissedOutgoingIcon />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Retiros Santiago" />}
        </ListItemButton>
      )}
      {isVisible("RetirosInterRegion") && (
        <ListItemButton
        component={Link}
        to="/RetirosInterRegion"
        sx={{
          bgcolor: location.pathname === "/RetirosInterRegion" ? "white" : "transparent",
            color: location.pathname === "/RetirosInterRegion" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/RetirosInterRegion" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/RetirosInterRegion" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/RetirosInterRegion" ? "10px" : "0px",
            margin: location.pathname === "/RetirosInterRegion" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <ArrowOutwardIcon />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Retiros Inter-Region" />}
        </ListItemButton>
      )}
      {isVisible("ManifiestoRutaAgentes") && (
        <ListItemButton
        component={Link}
        to="/ManifiestoRutaAgentes"
        sx={{
          bgcolor: location.pathname === "/ManifiestoRutaAgentes" ? "white" : "transparent",
            color: location.pathname === "/ManifiestoRutaAgentes" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/ManifiestoRutaAgentes" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/ManifiestoRutaAgentes" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/ManifiestoRutaAgentes" ? "10px" : "0px",
            margin: location.pathname === "/ManifiestoRutaAgentes" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <OpenWithIcon />
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Liberación Ruta Agentes" />}
        </ListItemButton>
      )}
      {isVisible("EdicionAgenteOrden") && (
        <ListItemButton
        component={Link}
        to="/EdicionAgenteOrden"
        sx={{
          bgcolor: location.pathname === "/EdicionAgenteOrden" ? "white" : "transparent",
            color: location.pathname === "/EdicionAgenteOrden" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/EdicionAgenteOrden" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/EdicionAgenteOrden" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/EdicionAgenteOrden" ? "10px" : "0px",
            margin: location.pathname === "/EdicionAgenteOrden" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>
        <ChangeCircleIcon/>
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Actualizar Agente Ordenes" />}
        </ListItemButton>
      )}

      {isVisible("ReporteAutomaticoCliente") && (
        <ListItemButton
        component={Link}
        to="/ReporteAutomaticoCliente"
        sx={{
          bgcolor: location.pathname === "/ReporteAutomaticoCliente" ? "white" : "transparent",
            color: location.pathname === "/ReporteAutomaticoCliente" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/ReporteAutomaticoCliente" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/ReporteAutomaticoCliente" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/ReporteAutomaticoCliente" ? "10px" : "0px",
            margin: location.pathname === "/ReporteAutomaticoCliente" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>

        <ManageHistoryIcon/>
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Generar reporte automático" />}
        </ListItemButton>
      )}

{isVisible("ControlChoferes") && (
        <ListItemButton
        component={Link}
        to="/ControlChoferes"
        sx={{
          bgcolor: location.pathname === "/ControlChoferes" ? "white" : "transparent",
            color: location.pathname === "/ControlChoferes" ? "#041562" : "white",
            ".MuiListItemText-primary": {
              fontSize: "0.775rem",
            },
            pl: isDrawerOpen ? 4 : 2,
            "& .MuiListItemIcon-root": {
              color: location.pathname === "/ControlChoferes" ? "#041562" : "white",
              minWidth: isDrawerOpen ? "auto" : 25,
              mr: isDrawerOpen ? 2 : "auto",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              color: location.pathname === "/ControlChoferes" ? "#041562" : "white",
            },
            "&:hover": {
              bgcolor: "white",
              ".MuiListItemIcon-root": { color: "#041562" },
              ".MuiListItemText-primary": { color: "#041562" },
              borderRadius: "10px",
            },
            borderRadius: location.pathname === "/ControlChoferes" ? "10px" : "0px",
            margin: location.pathname === "/ControlChoferes" ? "8px" : "0px",
            overflow: "hidden",
        }}
        >
        <ListItemIcon>

        <AirlineSeatReclineNormalIcon/>
        </ListItemIcon>
        {isDrawerOpen && <ListItemText primary="Control Choferes" />}
        </ListItemButton>
      )}

      </List>
      </Collapse>
      </>
    )
  );
}

export default MenuSac;

