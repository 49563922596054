import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Grid,
  Card,
  IconButton,
  CardContent,
  InputAdornment,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Paper,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  obtenerDetallesOrden,
  obtenerDetallesOrdenCodigoBarra,
  obtenerDetallesPorGuia,
  obtenerClientesControlSac,
  ObtenerLaboratorioNovo,
} from "../../services/ReportesServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@reactuiutils/horizontal-timeline/timeline.css";
import { GoogleMap, Marker } from "@react-google-maps/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { es } from "date-fns/locale";
import { formatInTimeZone } from "date-fns-tz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { parse, parseISO, compareDesc } from "date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTextField from "../../utils/CustomTextField";
import CustomAutocomplete from "../../utils/CustomAutocomplete";
import ImageZoom from "react-image-zooom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ConsultaIndividual = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const idUsuario = userInfo ? userInfo.id : null;
  const [od, setOd] = useState("");
  const [codigo, setCodigo] = useState("");
  const [odInfo, setOdInfo] = useState(null);
  const [certificaciones, setCertificaciones] = useState(null);
  const [activeCertification, setActiveCertification] = useState(null);
  const [selectedCertification, setSelectedCertification] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [timelineEventsRendiciones, setTimelineEventsRendiciones] = useState([]);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [guia, setGuia] = useState("");
  const [searchAttempted, setSearchAttempted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCodigoBarra, setLoadingCodigoBarra] = useState(false);
  const [loadingPorGuia, setLoadingPorGuia] = useState(false);
  const [nombreLaboratorio, setNombreLaboratorio] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [transportista, setTransportista] = useState("");
  const [eventsByType, setEventsByType] = useState({});
  const [selectedManifiestoType, setSelectedManifiestoType] = useState(null);
  const [manifiestoModalOpen, setManifiestoModalOpen] = useState(false);

  const procesoOrder = [
    "INHOUSE",
    "ENTRADAANDEN",
    "SALIDAANDEN",
    "ENTRADAAGENTE",
    "SALIDARUTA",
    "SALIDARUTARETIROS",
    "SALIDAAGENTE",
    "INGRESOSANTIAGO",
    "RETORNOCLIENTES",
    "MANIFIESTOAPP",
  ];

  const procesoTitleMap = {
    INHOUSE: "MANIFIESTO INHOUSE",
    ENTRADAANDEN: "ENTRADA ANDÉN",
    SALIDAANDEN: "SALIDA ANDÉN",
    ENTRADAAGENTE: "ENTRADA AGENTE",
    SALIDARUTA: "SALIDA RUTA",
    SALIDARUTARETIROS: "MANIFIESTO RETIROS",
    SALIDAAGENTE: "SALIDA AGENTE",
    RETORNOCLIENTES: "RETORNO CLIENTES",
    INGRESOSANTIAGO: "INGRESO BODEGA",
    MANIFIESTOAPP: "MANIFIESTO APP",
  };

  const prepareTimelineEvents = (manifiestoDataArray) => {
    const eventsByType = {};

    manifiestoDataArray.forEach((manifiestoData) => {
      const procesoKey = manifiestoData.PROCESO;
      const title = procesoTitleMap[procesoKey] || "MANIFIESTO DESCONOCIDO";

      if (!eventsByType[procesoKey]) {
        eventsByType[procesoKey] = {
          title: title,
          manifests: [],
        };
      }

      const event = {
        id: manifiestoData.ID,
        fecha: new Date(manifiestoData.FH_CIERRE).toLocaleString("es-CL", { timeZone: "America/Santiago" }),
        chofer: manifiestoData.NOMBRE_CHOFER,
        usuario: manifiestoData.NOMBRE_USUARIO,
      };

      eventsByType[procesoKey].manifests.push(event);
    });

    return eventsByType;
  };

  /* Función para obtener las imágenes */
  const getImages = (selectedCertification) => {
    const images = [];
    const base64Prefix = "data:image/jpeg;base64,";
    [selectedCertification.FOTO1, selectedCertification.FOTO2, selectedCertification.FOTO3].forEach((img) => {
      if (img && img !== "FALSE") {
        const formattedImage = img.startsWith(base64Prefix) ? img : base64Prefix + img;
        images.push(formattedImage);
      }
    });
    return images;
  };

  const getSliderSettings = (imageCount) => {
    return {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: true,
      draggable: false,
      swipe: false,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      arrows: imageCount > 1,
    };
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "red", right: "10px" }}
        onClick={onClick}
      ></div>
    );
  }

  const formatDateToSantiago = (dateString) => {
    const timeZone = "America/Santiago";
    const date = parseISO(dateString);
    return formatInTimeZone(date, timeZone, "dd-MM-yyyy, HH:mm:ss", { locale: es });
  };

  const formatDateToSantiagoCertificaciones = (dateString) => {
    const timeZone = "America/Santiago";
    const date = parseISO(dateString);
    return formatInTimeZone(date, timeZone, "dd-MM-yyyy", { locale: es });
  };

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "red", left: "10px", zIndex: 1 }}
        onClick={onClick}
      ></div>
    );
  }

  const isValidCoordinate = (coordinate) => {
    return coordinate && !isNaN(coordinate);
  };

  const renderGoogleMap = () => {
    if (
      selectedCertification &&
      isValidCoordinate(selectedCertification.LAT_TERRENO) &&
      isValidCoordinate(selectedCertification.LONG_TERRENO)
    ) {
      return (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "270px" }}
          center={{
            lat: parseFloat(selectedCertification.LAT_TERRENO),
            lng: parseFloat(selectedCertification.LONG_TERRENO),
          }}
          zoom={15}
        >
          <Marker
            position={{
              lat: parseFloat(selectedCertification.LAT_TERRENO),
              lng: parseFloat(selectedCertification.LONG_TERRENO),
            }}
          />
        </GoogleMap>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
            height: "270px",
            backgroundColor: "#ccc",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">Ubicación no disponible</Typography>
        </div>
      );
    }
  };

  const handleDetailsClick = (certificacion) => {
    setSelectedCertification(certificacion);
    setDialogOpen(true);
  };

  const handleCertificationClick = (certificacion) => {
    if (activeCertification && activeCertification.ID === certificacion.ID) {
      setActiveCertification(null);
    } else {
      setActiveCertification(certificacion);
    }
  };

  const handleOpenImageDialog = (img) => {
    setCurrentImage(img);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  const handleTextFieldChange = (event) => {
    setOd(event.target.value);
  };

  const handleTextFieldChangeCodigoBarra = (event) => {
    setCodigo(event.target.value);
  };

  const parseDateCertificaciones = (dateStr) => {
    return parse(dateStr, "dd/MM/yyyy HH:mm:ss", new Date());
  };

  const handleBuscarPorGuia = async (event) => {
    event.preventDefault();
    setSearchAttempted(true);
    setLoadingPorGuia(true);
    if (!guia || !selectedClient) {
      toast.error("Por favor, seleccione un cliente y ingrese la guía.");
      setLoading(false);
      return;
    }
    try {
      const response = await obtenerDetallesPorGuia(guia, selectedClient.id);
      if (response && response.datosOrden && response.datosOrden.length > 0) {
        toast.success("Datos cargados con éxito!");
        if (response.certificaciones) {
          const certificacionesOrdenadas = response.certificaciones.sort((a, b) => {
            const dateA = parseDateCertificaciones(a.FH_GESTION);
            const dateB = parseDateCertificaciones(b.FH_GESTION);
            return compareDesc(dateA, dateB);
          });
          setCertificaciones(certificacionesOrdenadas);
        } else {
          setCertificaciones([]);
        }
        if (response.datosOrden && response.datosOrden.length > 0) {
          setOdInfo(response.datosOrden[0]);
        } else {
          setOdInfo(null);
        }
        if (response.detallesManifiestos && response.detallesManifiestos.length > 0) {
          const eventsByType = prepareTimelineEvents(response.detallesManifiestos);
          setEventsByType(eventsByType);
        } else {
          setEventsByType({});
        }
        if (response.rendiciones && response.rendiciones.length > 0) {
          const timelineEventsRendiciones = prepareTimelineEventsRendiciones(response.rendiciones);
          setTimelineEventsRendiciones(timelineEventsRendiciones);
          if (timelineEventsRendiciones.length > 0) {
            console.log("Detalles de rendiciones cargados con éxito!");
          } else {
            console.log("No se encontraron detalles de rendiciones.");
          }
        }
      } else {
        toast.error("Guía no existe.");
        setOdInfo(null);
        setCertificaciones([]);
        setTimelineEventsRendiciones([]);
      }
    } catch (error) {
      console.error("Error al obtener detalles de la guía:", error);
      toast.error("Error al obtener detalles de la guía.");
      setOdInfo(null);
      setCertificaciones([]);
      setTimelineEventsRendiciones([]);
    }
    setGuia("");
    setLoadingPorGuia(false);
  };

  /* aqui buscamos por codigo de barra */
  const handleFormSubmitCodigoBarra = async (event) => {
    event.preventDefault();
    setSearchAttempted(true);
    setLoadingCodigoBarra(true);
    if (!codigo || !selectedClient) {
      toast.error("Por favor, seleccione un cliente y ingrese un codigo.");
      setLoadingCodigoBarra(false);
      return;
    }
    try {
      const response = await obtenerDetallesOrdenCodigoBarra(codigo, selectedClient.id);
      if (response && response.datosOrden && response.datosOrden.length > 0) {
        toast.success("Datos cargados con éxito!");
        setOdInfo(response.datosOrden[0]);
        if (response.certificaciones) {
          const certificacionesOrdenadas = response.certificaciones.sort((a, b) => {
            const dateA = parseDateCertificaciones(a.FH_GESTION);
            const dateB = parseDateCertificaciones(b.FH_GESTION);
            return compareDesc(dateA, dateB);
          });
          setCertificaciones(certificacionesOrdenadas);
        } else {
          setCertificaciones([]);
        }

        if (response.datosOrden && response.datosOrden.length > 0) {
          setOdInfo(response.datosOrden[0]);
          if (response.datosOrden[0].COD_CLIENTE === 632) {
            const codigoLaboratorio = response.datosOrden[0].GUIA.slice(0, 2);
            try {
              const data = { COD_LAB: codigoLaboratorio };
              const laboratorioResponse = await ObtenerLaboratorioNovo(data);
              if (laboratorioResponse && laboratorioResponse[0].LABORATORIO) {
                setNombreLaboratorio(laboratorioResponse[0].LABORATORIO);
              } else {
                setNombreLaboratorio("Laboratorio desconocido");
              }
            } catch (error) {
              setNombreLaboratorio("Error al obtener laboratorio");
            }
          } else {
            setNombreLaboratorio("");
          }
        } else {
          setOdInfo(null);
        }
        if (response.detallesManifiestos && response.detallesManifiestos.length > 0) {
          const eventsByType = prepareTimelineEvents(response.detallesManifiestos);
          setEventsByType(eventsByType);
        } else {
          setEventsByType({});
        }
        if (response.rendiciones && response.rendiciones.length > 0) {
          const timelineEventsRendiciones = prepareTimelineEventsRendiciones(response.rendiciones);
          setTimelineEventsRendiciones(timelineEventsRendiciones);
          if (timelineEventsRendiciones.length > 0) {
            console.log("Detalles de rendiciones cargados con éxito!");
          } else {
            console.log("No se encontraron detalles de rendiciones.");
          }
        } else {
          setTimelineEventsRendiciones([]);
          console.log("No se encontraron detalles de rendiciones.");
        }
      } else {
        toast.error("Orden no existe.");
        setOdInfo(null);
        setCertificaciones([]);
        setTimelineEventsRendiciones([]);
      }
    } catch (error) {
      console.error("Error al obtener detalles de la orden:", error);
      toast.error("Error al obtener detalles de la orden.");
      setOdInfo(null);
      setCertificaciones([]);
      setTimelineEventsRendiciones([]);
    }
    setCodigo("");
    setLoadingCodigoBarra(false);
  };

  /* aqui buscamos por od */
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setSearchAttempted(true);
    setLoading(true);
    try {
      const response = await obtenerDetallesOrden(od);
      console.log(response);
      if (response && response.datosOrden && response.datosOrden.length > 0) {
        toast.success("Datos cargados con éxito!");
        setOdInfo(response.datosOrden[0]);
        if (response.certificaciones) {
          const certificacionesOrdenadas = response.certificaciones.sort((a, b) => {
            const dateA = parseDateCertificaciones(a.FH_GESTION);
            const dateB = parseDateCertificaciones(b.FH_GESTION);
            return compareDesc(dateA, dateB);
          });
          setCertificaciones(certificacionesOrdenadas);
        } else {
          setCertificaciones([]);
        }

        if (response.datosOrden && response.datosOrden.length > 0) {
          setOdInfo(response.datosOrden[0]);
          if (response.datosOrden[0].COD_CLIENTE === 632) {
            const codigoLaboratorio = response.datosOrden[0].GUIA.slice(0, 2);
            try {
              const data = { COD_LAB: codigoLaboratorio };
              const laboratorioResponse = await ObtenerLaboratorioNovo(data);
              if (laboratorioResponse && laboratorioResponse[0].LABORATORIO) {
                setNombreLaboratorio(laboratorioResponse[0].LABORATORIO);
              } else {
                console.log("Laboratorio no encontrado");
                setNombreLaboratorio("Laboratorio desconocido");
              }
            } catch (error) {
              console.error("Error al obtener el laboratorio:", error);
              setNombreLaboratorio("Error al obtener laboratorio");
            }
          } else {
            console.log("El cliente no es 632, no se consulta el laboratorio.");
            setNombreLaboratorio("");
          }

          if (response.detallesManifiestos && response.detallesManifiestos.length > 0) {
            const eventsByType = prepareTimelineEvents(response.detallesManifiestos);
            setEventsByType(eventsByType);
          } else {
            setEventsByType({});
            console.log("No se encontraron detalles de manifiestos.");
          }
        }

        if (response.rendiciones && response.rendiciones.length > 0) {
          const timelineEventsRendiciones = prepareTimelineEventsRendiciones(response.rendiciones);
          setTimelineEventsRendiciones(timelineEventsRendiciones);
          if (timelineEventsRendiciones.length > 0) {
            console.log("Detalles de rendiciones cargados con éxito!");
          } else {
            console.log("No se encontraron detalles de rendiciones.");
          }
        } else {
          setTimelineEventsRendiciones([]);
          console.log("No se encontraron detalles de rendiciones.");
        }
      } else {
        toast.error("Orden no existe.");
        setOdInfo(null);
        setCertificaciones([]);
        setTimelineEventsRendiciones([]);
      }
    } catch (error) {
      console.error("Error al obtener detalles de la orden:", error);
      toast.error("Error al obtener detalles de la orden.");
      setOdInfo(null);
      setCertificaciones([]);
      setTimelineEventsRendiciones([]);
    }
    setOd("");
    setLoading(false);
  };

  const prepareTimelineEventsRendiciones = (rendiciones) => {
    return rendiciones.map((rendicion, index) => ({
      id: rendicion.COD_MANIFIESTO,
      title: `Guía: ${rendicion.GUIA}`,
      subtitle: `Fecha: ${new Date(rendicion.FH_MANIFIESTO).toLocaleDateString()}${
        rendicion.COD_MANIFIESTO ? `, Código manifiesto: ${rendicion.COD_MANIFIESTO}` : ""
      }`,
    }));
  };

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await obtenerClientesControlSac();
        setClientes(response);
      } catch (error) {
        console.error("Error al obtener la lista de clientes:", error);
      }
    };
    fetchClientes();
  }, []);

  /* Función para guardar el número de orden en localStorage */
  const saveOdToLocalStorage = (od) => {
    try {
      localStorage.setItem("od", od);
    } catch (e) {
      console.error("Error al guardar en localStorage:", e);
    }
  };

  /* Hook para guardar el número de orden cuando cambie */
  useEffect(() => {
    if (od) {
      saveOdToLocalStorage(od);
    }
  }, [od]);

  /* Hook para restaurar el número de orden del localStorage al cargar el componente */
  useEffect(() => {
    const savedOd = localStorage.getItem("od");
    if (savedOd) {
      setOd(savedOd);
    }
  }, []);

  const dividerStyle = { height: "1px", backgroundColor: "#DA251C", margin: "5px 0" };

  return (
    <Container maxWidth="lg" style={{ padding: "5px" }}>
    {/* Buscar por OD */}
    <Paper style={{ padding: "15px", marginBottom: "10px" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <form onSubmit={handleFormSubmit}>
            <Tooltip title="Ingrese una OD para la búsqueda">
              <CustomTextField
                fullWidth
                label="Buscar por OD"
                value={od}
                onChange={handleTextFieldChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          </form>
        </Grid>
      </Grid>
    </Paper>

    {/* Seleccionar Cliente + Buscar por Guía o Código de Barra */}
    <Paper style={{ padding: "15px", marginBottom: "10px" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <CustomAutocomplete
            fullWidth
            label="Seleccionar Cliente"
            options={clientes.map((cliente) => ({
              id: cliente.ID,
              nombre: cliente.NOMBRE + " (ID: " + cliente.ID + ")",
            }))}
            getOptionLabel={(option) => option.nombre}
            value={selectedClient}
            onChange={(event, newValue) => {
              setSelectedClient(newValue);
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <form onSubmit={handleFormSubmitCodigoBarra}>
            <Tooltip title="Ingrese un código de barra o id referencia para la búsqueda">
              <CustomTextField
                fullWidth
                label="Buscar por Código de Barra o id referencia"
                value={codigo}
                onChange={handleTextFieldChangeCodigoBarra}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" disabled={loadingCodigoBarra}>
                        {loadingCodigoBarra ? <CircularProgress size={24} /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          </form>
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={handleBuscarPorGuia}>
            <Tooltip title="Ingrese una guía para la búsqueda">
              <CustomTextField
                fullWidth
                label="Buscar por Guía"
                value={guia}
                onChange={(e) => setGuia(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" disabled={loadingPorGuia}>
                        {loadingPorGuia ? <CircularProgress size={24} /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          </form>
        </Grid>
      </Grid>
    </Paper>
      {odInfo && (
        <Grid item xs={12}>
          <Card style={{ marginBottom: "5px", backgroundColor: "#f7f7f7" }}>
            <CardContent>
              <Grid item xs={4}>
                <div style={{ color: "#041562", fontSize: "24px", marginBottom: "5px" }}>
                  <strong>Informacion de la orden: </strong>
                  <strong style={{ color: "#DA251C" }}>{odInfo.OD || ""}</strong>
                </div>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Bultos:</strong> {odInfo.BULTOS || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Alto:</strong> {odInfo.ALTO || ""}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Peso:</strong> {odInfo.PESO || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Ancho:</strong> {odInfo.ANCHO || ""}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Largo:</strong> {odInfo.LARGO || ""}
                  </div>
                </Grid>
              </Grid>
              <div style={dividerStyle}></div>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Cliente:</strong> {odInfo.NOMBRE_CLIENTE || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Destinatario:</strong> {odInfo.NOMBRE || ""}
                  </div>

                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    {odInfo.COD_CLIENTE === 632 ? (
                      <>
                        <strong>Documento:</strong> {odInfo.GUIA || ""}
                        <br />
                        <strong>{odInfo.GUIA[2] === "G" ? "Guía" : "Factura"}:</strong> {odInfo.GUIA.slice(3)}
                        <br />
                        <strong>Laboratorio:</strong> {nombreLaboratorio}
                        <br />
                        <strong>Zona Almacenamiento:</strong> {odInfo.ZONA_ALMACENAMIENTO || ""}
                      </>
                    ) : (
                      <>
                        <strong>Guía:</strong> {odInfo.GUIA || ""}
                      </>
                    )}
                  </div>

                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    {odInfo.COD_CLIENTE === 749 ? (
                      <>
                        <strong>Documento:</strong> {odInfo.ID_REFERENCIA || ""}
                        <br />
                      </>
                    ) : null}
                  </div>

                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>OD:</strong> {odInfo.OD || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>OD Papel:</strong> {odInfo.OD_PAPEL || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Codigo de Barra:</strong> {odInfo.COD_BARRA || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Id Referencia:</strong> {odInfo.ID_REFERENCIA || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Centro de costo:</strong> {odInfo.CENTRO_COSTO || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Nota:</strong> {odInfo.NOTA}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Fecha Digitación: </strong>
                    {odInfo ? formatDateToSantiago(odInfo.FH_DIGITACION) : "Cargando o no disponible"}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Fecha de creacion: </strong>
                    {new Date(odInfo.FH_SYS).toLocaleString("es-CL", { timeZone: "America/Santiago" })}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px", wordBreak: "break-word" }}>
                    <strong>Codigo Dispositivo:</strong> {odInfo.COD_DISPOSITIVO}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px", wordBreak: "break-word" }}>
                    <strong>Codigo Embalaje:</strong> {odInfo.COD_EMBALAJE}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Comuna Origen:</strong> {odInfo.NOMBRE_COMUNA_ORIGEN || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Rut:</strong> {odInfo.RUT || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Dirección:</strong> {odInfo.DIRECCION || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Tipo de negocios:</strong> {odInfo.TIPO_NEGOCIO || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Canal:</strong> {odInfo.CANAL || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Contacto Destino:</strong> {odInfo.CONTACTO_DESTINO || ""}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Comuna Destino:</strong> {odInfo.NOMBRE_COMUNA_DESTINO || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Telefono:</strong> {odInfo.TELEFONO || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Tipo de carga:</strong> {odInfo.TIPO_CARGA || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    {odInfo.TIPO_ORDEN === odInfo.TIPO_ORDEN_INICIAL ? (
                      <>
                        <strong>Tipo de orden:</strong> {odInfo.TIPO_ORDEN || ""}
                      </>
                    ) : (
                      <>
                        <div>
                          <strong>Tipo de orden actual:</strong> {odInfo.TIPO_ORDEN || ""}
                        </div>
                        <div>
                          <strong>Tipo de orden inicial:</strong> {odInfo.TIPO_ORDEN_INICIAL || ""}
                        </div>
                      </>
                    )}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Via:</strong> {odInfo.VIA || ""}
                  </div>
                  {(userInfo.tipo_usuario === "FACTURACION" || userInfo.tipo_usuario === "ADMIN") && (
                    <div style={{ color: "#041562", fontSize: "14px" }}>
                      <strong>Transporte:</strong> {transportista || ""}
                    </div>
                  )}
                </Grid>
              </Grid>
              <div style={dividerStyle}></div>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div style={{ marginBottom: "5px", color: "#041562", fontSize: "14px" }}>
                    <strong>Datos retiros:</strong>
                  </div>
                  <Grid item xs={12}>
                    {odInfo.NOMBRE_RETIRO || odInfo.DIRECCION_RETIRO || odInfo.CONTACTO ? (
                      <Grid item xs={4}>
                        <div style={{ color: "#041562", fontSize: "14px" }}>
                          <strong>Nombre de retiro:</strong> {odInfo.NOMBRE_RETIRO || ""}
                        </div>
                        <div style={{ color: "#041562", fontSize: "14px" }}>
                          <strong>Direccion del retiro:</strong> {odInfo.DIRECCION_RETIRO || ""}
                        </div>
                        <div style={{ color: "#041562", fontSize: "14px" }}>
                          <strong>Contacto Retiro:</strong> {odInfo.CONTACTO || ""}
                        </div>
                        <div style={{ color: "#041562", fontSize: "14px" }}>
                          <strong>Telefono:</strong> {odInfo.TELEFONO_ORIGEN || ""}
                        </div>
                      </Grid>
                    ) : (
                      <div style={{ color: "#041562", fontSize: "14px" }}></div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <div style={dividerStyle}></div>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Manifiestos cedibles:</strong>
                  </div>
                  <List style={{ display: "flex", flexDirection: "row", padding: 0, overflowX: "auto" }}>
                    {timelineEventsRendiciones.map((event, index) => (
                      <ListItem key={index} style={{ width: "auto", flex: "none" }}>
                        <ListItemIcon>
                          <IconButton
                            onClick={() =>
                              window.open(
                                `https://app.cargoex.cl/app/cargoex/Cron/manifiestoCedibles?idManf=${event.id}`,
                                "_blank",
                              )
                            }
                          >
                            <FileOpenIcon style={{ color: "#041562" }} />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemText
                          primary={event.title}
                          secondary={event.subtitle}
                          primaryTypographyProps={{ style: { fontSize: "0.7rem" } }}
                          secondaryTypographyProps={{ style: { fontSize: "0.6rem" } }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      {Object.keys(eventsByType).length > 0 && (
        <Paper style={{ marginBottom: "5px", padding: "10px" }}>
          <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
            {procesoOrder.map((key, index) => {
              const title = procesoTitleMap[key];
              const events = eventsByType[key]?.manifests || [];
              return (
                <React.Fragment key={key}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={events.length === 0}
                    onClick={() => {
                      setSelectedManifiestoType(key);
                      setManifiestoModalOpen(true);
                    }}
                    style={{
                      marginRight: "5px",
                      marginBottom: "5px",
                      fontSize: "0.54rem",
                      padding: "4px 8px",
                    }}
                  >
                    {title}
                  </Button>
                  {index < procesoOrder.length - 1 && (
                    <ArrowForwardIcon style={{ fontSize: "1rem", marginRight: "5px" }} />
                  )}
                </React.Fragment>
              );
            })}
          </Box>
        </Paper>
      )}
      {searchAttempted &&
        !loading &&
        (certificaciones && certificaciones.length > 0 ? (
          <VerticalTimeline lineColor={"#f0f5fc"} style={{ borderLeft: "2px solid #ccc", fontSize: "10px" }}>
            {certificaciones.map((certificacion, index) => {
              const estadoColor = certificacion.ESTADO_DESCRIPCION === "ENTREGA EXITOSA" ? "#28A745" : "#DA251C";
              return (
                <VerticalTimelineElement
                  contentStyle={{ background: "#f0f5fc", color: "black", padding: "10px" }}
                  contentArrowStyle={{ borderRight: "7px solid  #f0f5fc" }}
                  key={index}
                  date={certificacion.FH_GESTION}
                  iconStyle={{ background: "#041562", color: "#fff" }}
                  icon={<LocalShippingIcon />}
                  style={{ fontSize: "5px" }}
                  onClick={() => handleCertificationClick(certificacion)}
                >
                  <h3 className="vertical-timeline-element-title" style={{ fontSize: "16px", color: estadoColor }}>
                    {certificacion.ESTADO_DESCRIPCION}
                    <IconButton
                      style={{ marginLeft: "5px" }}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDetailsClick(certificacion);
                      }}
                    >
                      <TouchAppIcon />
                    </IconButton>
                  </h3>
                </VerticalTimelineElement>
              );
            })}
          </VerticalTimeline>
        ) : odInfo && Object.keys(eventsByType).length > 0 ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              fontSize: "16px",
              color: "#DA251C",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            EN TRANSITO
            <LocalShippingIcon style={{ marginLeft: "10px" }} />
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              fontSize: "16px",
              color: "#DA251C",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            INFORMADO SIN MOVIMIENTOS
          </div>
        ))}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>
          Detalles de la Orden: <span style={{ color: "#DA251C" }}>{odInfo ? odInfo.OD : "Cargando..."}</span> con Fecha
          de digitación:{" "}
          {odInfo ? formatDateToSantiagoCertificaciones(odInfo.FH_DIGITACION) : "Cargando o no disponible"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {selectedCertification && (
                <>
                  <Typography variant="body1">
                    <strong>Nombre receptor:</strong> {selectedCertification.NOMBRE}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Rut:</strong> {selectedCertification.RUT}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Fecha de Gestión:</strong> {selectedCertification.FH_GESTION}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Estado:</strong> {selectedCertification.ESTADO_DESCRIPCION}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Comuna Origen:</strong> {selectedCertification.COMUNA_ORIGEN}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Destino:</strong> {selectedCertification.DESTINO}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Chofer:</strong> {selectedCertification.NOMBRE_CHOFER}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Coordenadas de gestion:</strong> {selectedCertification.LAT_TERRENO},
                    {selectedCertification.LONG_TERRENO}
                  </Typography>{" "}
                  <Typography variant="body1">
                    <strong>Distancia en metros:</strong> {selectedCertification.DISTANCIA_METROS}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Conexion:</strong> {selectedCertification.CONEXION}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Nota:</strong> {selectedCertification.NOTA}
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={4}>
              {selectedCertification && (
                <Slider {...getSliderSettings(getImages(selectedCertification).length)}>
                  {getImages(selectedCertification).map((img, index) => (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={img}
                        alt={`Foto ${index + 1}`}
                        style={{ width: "100%", height: "270px", cursor: "pointer" }}
                        onClick={() => handleOpenImageDialog(img)}
                      />
                      <span style={{ marginTop: "10px" }}>haz click a la imagen para ver detalle</span>
                    </div>
                  ))}
                </Slider>
              )}
            </Grid>

            <Grid item xs={4}>
              {renderGoogleMap()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openImageDialog}
        onClose={handleCloseImageDialog}
        aria-labelledby="image-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent
          style={{
            padding: 0,
            overflow: "auto",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ImageZoom
              src={currentImage}
              alt="Enlarged pic"
              zoom="300"
              width="100%"
              height="auto"
              style={{ cursor: "zoom-in" }}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={manifiestoModalOpen} onClose={() => setManifiestoModalOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>{eventsByType[selectedManifiestoType]?.title || ""}</DialogTitle>
        <DialogContent>
          {eventsByType[selectedManifiestoType] &&
            eventsByType[selectedManifiestoType].manifests.map((event, index) => (
              <div key={index}>
                <Typography variant="body1">
                  <strong>ID del manifiesto:</strong> {event.id}
                </Typography>
                <Typography variant="body1">
                  <strong>Fecha:</strong> {event.fecha}
                </Typography>
                <Typography variant="body1">
                  <strong>Chofer:</strong> {event.chofer}
                </Typography>
                <Typography variant="body1">
                  <strong>Usuario:</strong> {event.usuario}
                </Typography>
                <IconButton
                  onClick={() =>
                    window.open(`https://backend.cargoexteam.cl/medicion/creacionManifiestoPDF/${event.id}`, "_blank")
                  }
                >
                  <DescriptionIcon style={{ color: "#DA251C" }} />
                </IconButton>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "#DA251C",
                    margin: "5px 0",
                  }}
                ></div>
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setManifiestoModalOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ConsultaIndividual;
