import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Grid,
  TextField,
  Autocomplete,
  Checkbox, FormControlLabel, FormGroup,Tooltip, Paper,Modal, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";

import {getClients, getReportesAutomaticosProgramados, getDestinatarios, getDestinatariosAsignables, getRegistroDestinatariosCorreoEnviado, deleteActualReporteAutomatico, insertReporteAtuomaticoProgramado} from "../../services/ReporteAutomaticoService"
import DaySchedule from '../../components/ReportesComponentes/componentesReporteAutomatico/Horario';


const DeleteConfirmDialog = ({ open, onClose, onConfirm }) => {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Confirmar eliminación</DialogTitle>
    <DialogContent>
    ¿Estás seguro de que quieres eliminar el registro? Esta acción no se puede deshacer.
    </DialogContent>
    <DialogActions>
    <Button onClick={handleClose} color="primary">
    Cancelar
    </Button>
    <Button onClick={handleConfirm} style={{color:"#DA251C"}}>
    Eliminar
    </Button>
    </DialogActions>
    </Dialog>
  );
};

const ReporteAutomaticoCliente = () => {
  const [openConfirm, setOpenConfirm] = useState(false);

  const [schedule, setSchedule] = useState({
    Lunes: [],
    Martes: [],
    Miercoles: [],
    Jueves: [],
    Viernes: [],
    Sabado: [],
    Domingo: [],
  });

  const [clients, setClients ] = useState([])
  const [selectedDestinatarios, setSelectedDestinatarios] = useState([]);
  const [destinatariosYaAsociados, setDestinatariosYaAsociados] = useState([])
  const [destinatariosAsignablesPorCliente, setDestinatariosAsignablesPorCliente] = useState([])  
  const [reportesYaAsociados,setReportesYaAsociados] = useState([])
  const [selectedClient, setSelectedClient] = useState(null);
  const [destinatariosStatus, setDestinatariosStatus] = useState([])
  // Manejo de errores
  const [reportesError,setReportesError] = useState(null)
  const [destinatariosError, setDestinatariosError] = useState(null)
  const [clientReportError, setClientReportError] =  useState(null)
  const [deleteReportError, setDeleteReportError] =  useState(null)
  const [destinatariosStatusError, setDestinatariosStatusError] = useState(null)
  const [inputDestinatarioError, setInputDestinatarioError] = useState(null)

  // Logica del modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Estados para carga visual
  const [isCompleted, setIsCompleted] = useState({horario:false, destinatarios:false,readyToSave:false})
  const [btnDisabled, setBtnDisabled] =  useState(false)
  const [isLoading, setIsLoading] = useState(true)
  // handler cambio de destinatarios
  const handleChange = (event) => {
    const { name, checked } = event.target;

    if(!checked && (destinatariosYaAsociados.length > 0 || selectedDestinatarios.length > 0)){
      let isAllFalse = 1
      for(let email in selectedDestinatarios){
        if(!selectedDestinatarios[email]) isAllFalse += 1
      }
      if(isAllFalse === Object.keys(selectedDestinatarios).length) setIsCompleted({horario:true, destinatarios:false, readyToSave:false})
    } 

    setSelectedDestinatarios((prev) => ({
      ...prev,
      [name]: checked,
    }));

  };

  // Manejar seleccion de horas
  const handleHourSelect = (day, hour) => {
    setSchedule(prev => ({
      ...prev,
      [day]: prev[day].includes(hour)
      ? prev[day].filter(h => h !== hour)
      : [...prev[day], hour].sort(),
    }));
  };

  // Manejar la eliminacion de la hora
  const handleDelete = (day, hourToDelete) => {
    setSchedule(prev => ({
      ...prev,
      [day]: prev[day].filter(hour => hour !== hourToDelete),
    }));
  };

  // Manejar la logica de envio de datos
  const handleSave = async () => {
    setBtnDisabled(true)

    let counter = 0
    // Validacion no enviar horario vacio
    for(let obj in schedule){
      if(schedule[obj].length === 0){
        counter += 1
      }
    }
    // Error en caso de no horario
    if(counter === 7){
      toast.warn("Porfavor añada un horario valido.")
      return
    }
    // Error no destinatario
    if(selectedDestinatarios.length === 0) {
      toast.warn("Porfavor seleccione al menos un destinatario.")
      return
    }
    let isValidLimitPerDay = false 
    // Validacion cantidad maxima de horarios
    for(let obj in schedule){
      const elements = schedule[obj]
      if(elements.length > 4) {
        toast.error(`Solo se aceptan un maximo de 4 reportes por dia. Verifique el dia: ${obj} - ${elements.join(", ")}`)
        isValidLimitPerDay = true
        break
      }
      setBtnDisabled(false)
    }
    // Envio de data si es que esta entre el rango valido de reportes por dia 
    if(!isValidLimitPerDay){
      for (let email in selectedDestinatarios) {
        if (selectedDestinatarios[email]) {
          const match = destinatariosYaAsociados.find(dest => dest.EMAIL === email);
          if (match) {
            selectedDestinatarios[email] = { 
              active: true, 
              nombre: match.NOMBRE 
            };
          }
        }
      }
      // Peticion POST para insertar el nuevo registro
      if (Object.keys(selectedDestinatarios).length > 0) {
        await insertReporteAtuomaticoProgramado(
          { cod_cliente: selectedClient.ID, horario: schedule, destinatarios: selectedDestinatarios },
          setDestinatariosError
        );
        toast.success("Reporte asignado con exito.")
        // Volver activar el boton de guardar y re fetchear el elemento
        setBtnDisabled(false)
        setSelectedClient(null)
        await getReportesAutomaticosProgramados(selectedClient.ID, setReportesYaAsociados, setReportesError)
      } else {
        toast.warning("Por favor seleccione un destinatario válido.");
      }
    }
  };

  // Logica para eliminacion de toda data relacionada / (Horario, destinatarios)
  const handleDeleteButton = async (cod) => {
    const deleteStatus = await deleteActualReporteAutomatico(cod, setDeleteReportError);
    if (deleteStatus) {
      // Save the success message in localStorage
      toast.success( "Eliminado correctamente.");
      setSelectedClient(null)
    } else {
      toast.error(`Algo fue mal durante la eliminación del reporte asociado al cliente: ${cod}`);
    }
  };

  // Get Clients 
  useEffect(() => {
    getClients(setClients, setClientReportError)
  }, [])

  // Manejar el estado del horario (Es valido / No es valido). Para render del resto del form
  useEffect(() => {
    if(selectedClient) {
      // contar cantidad de dias sin registros si son 7 significa que no relleno el horario.
        let counter = 0
      for(let dia in schedule){
        if(schedule[dia].length === 0) counter += 1
      }
      // Validar si el horario es valido es decir si tiene al menos un dia con reporte asignado.
        if(!isCompleted.horario && !isCompleted.destinatarios && !isCompleted.readyToSave){
          if(counter <= 6) setIsCompleted({horario:true, destinatarios:false, readyToSave:false})
        }else if (counter === 7) setIsCompleted({horario:false , destinatarios:false, readyToSave:false})

    }
  }, [selectedClient, schedule, isCompleted])


  // Manejar estado para renderizacion boton guardar horario (Destinatarios validos, debe estar seleccionado al menos uno para activar el boton de guardado) 
  useEffect(() => {
    if(selectedClient && isCompleted.horario && !isCompleted.destinatarios){
      if(destinatariosYaAsociados.length === 0 && selectedDestinatarios.length === 0) setIsCompleted({horario:true,destinatarios:false, readyToSave:false})
      if(destinatariosYaAsociados.length > 0 || selectedDestinatarios.length > 0){
        for(let dest in selectedDestinatarios){
          if(selectedDestinatarios[dest]){
            setIsCompleted({horario:true,destinatarios:true, readyToSave:true})
            break
          }
        }
      }
      if(!destinatariosYaAsociados.length > 0 && !selectedDestinatarios.length > 0) setIsCompleted({horario:true, destinatarios:false, readyToSave:false})
    }
  }, [selectedClient,destinatariosYaAsociados, selectedDestinatarios, isCompleted])

  // Manjear estado del cliente seleccionado con el fin de limpiar estados no necesario o agregar nuevos valores a estados requeridos
  useEffect(() => {
    if (selectedClient) {
      // Realiza las peticiones al cambiar el cliente
      getReportesAutomaticosProgramados(selectedClient.ID, setReportesYaAsociados, setReportesError);
      getDestinatarios(selectedClient.ID, setDestinatariosYaAsociados, setDestinatariosError);
      getDestinatariosAsignables(selectedClient.ID, setDestinatariosAsignablesPorCliente, setDestinatariosError);
      getRegistroDestinatariosCorreoEnviado(selectedClient.ID, setDestinatariosStatus, setDestinatariosStatusError)
      // Resetea estados relacionados
      setSchedule({
        Lunes: [],
        Martes: [],
        Miercoles: [],
        Jueves: [],
        Viernes: [],
        Sabado: [],
        Domingo: [],
      });
      setDestinatariosYaAsociados([]);
      setSelectedDestinatarios([]);
      setDestinatariosYaAsociados([]);
      setDestinatariosAsignablesPorCliente([]);
      setDestinatariosStatus([])
      setReportesYaAsociados([])
      setDestinatariosError(null)
      setReportesError(null);
      setClientReportError(null);
      setDeleteReportError(null);
      setDestinatariosStatusError(null);
      setInputDestinatarioError(null)
      setIsLoading(true)
    }    
    // Si no hay cliente seleccionado, resetea los estados
    setSchedule({
      Lunes: [],
      Martes: [],
      Miercoles: [],
      Jueves: [],
      Viernes: [],
      Sabado: [],
      Domingo: [],
    });
    setSelectedDestinatarios([]);
    setDestinatariosYaAsociados([]);
    setDestinatariosAsignablesPorCliente([]);
    setReportesYaAsociados([])
    setDestinatariosStatus([])
    setDestinatariosError(null)
    setReportesError(null);
    setClientReportError(null);
    setInputDestinatarioError(null)
    setDeleteReportError(null);
    setDestinatariosStatusError(null);
    setIsLoading(true)
  }, [selectedClient]); 


  // Renderizado de errores
  useEffect(() => {
    if(reportesError ){
      toast.warn(reportesError)
      setReportesError(null)
    }
    if(clientReportError ){
      toast.warn(clientReportError)
      setClientReportError(null)
    }
    if(deleteReportError){
      toast.warn(deleteReportError)
      setDeleteReportError(null)
    }
    if(destinatariosStatusError){
      toast.warn(destinatariosError)
      setDestinatariosStatusError(null)
    }
    if(reportesError && String(reportesError).includes("No se encontraron reportes")) setIsLoading(false)
  }, [destinatariosError, reportesError, clientReportError, deleteReportError, destinatariosStatusError, destinatariosYaAsociados])


  // Darle contexto anterior al horario. (Horas y Dias ya asignados)
  useEffect(() => {
    if (reportesYaAsociados.length > 0) {
      // Crear copia del horario
      const updatedSchedule = JSON.parse(JSON.stringify(schedule));

      reportesYaAsociados.forEach(_ => {
        for (let obj in updatedSchedule) {
          if (String(obj).toLowerCase() === String(_.DIA_SEMANA).toLowerCase()) {
            if (!updatedSchedule[obj].includes(_.HORA_ENVIO)) {
              updatedSchedule[obj].push(_.HORA_ENVIO);
            }
          }
        }
      });

      setSchedule(updatedSchedule);
      setIsLoading(false)
    }
  }, [reportesYaAsociados, schedule]); 


  // Manejar destinatarios / Marcar como checked aquellos presentes y Manejar el caso de destinatarios no validos.
    useEffect(() => {
      const defaultSelected = destinatariosYaAsociados.reduce((acc, { EMAIL }) => {
        acc[EMAIL] = true; 
        return acc;
      }, {});
      setSelectedDestinatarios(defaultSelected);

      if(selectedClient && destinatariosYaAsociados.length > 0 && destinatariosStatus.length > 0){
        const ds = destinatariosStatus.reduce((acc,va) => {
          if(va['ESTADO_ENVIO'] === "RECHAZADO"){
            if(!acc[va['ESTADO_ENVIO']] ){
              acc[va['ESTADO_ENVIO']] = []
            }
            if(!acc[va['ESTADO_ENVIO']].includes(va['EMAIL'])){
              acc[va['ESTADO_ENVIO']].push(va['EMAIL'])
            }
          }
          return acc
        }, {})
        if(ds.RECHAZADO && ds.RECHAZADO.length > 0){
          const filteredRejectedEmails = ds.RECHAZADO.filter(_ => 
            destinatariosYaAsociados.some(__ => __.EMAIL === _)
          );

          
          if(filteredRejectedEmails.length > 0) toast.error(`Porfavor deseleccione los siguientes destinatarios: ${filteredRejectedEmails.join(", ")} `)
          setDestinatariosStatusError(null)
          setDestinatariosStatus([])
        }
      }
    }, [selectedClient, destinatariosYaAsociados, destinatariosStatus, destinatariosStatusError]);



  return (
    <>
    <Box 
    sx={{
      minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        padding: {
          xs: 2,
            sm: 3,
            md: 4
        },
        width: '100%'
    }}
    >
    <Typography variant="h4" component="h2" color="primary">
    Asignación de Horarios de Envío de Reportes Automáticos
    </Typography>
    <Typography variant="body1" color="text.secondary">
    Define los días y horarios en los que se enviarán los correos a los destinatarios.
    </Typography>
    <Box
    sx={{
      maxWidth: 1200,
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        minHeight: selectedClient ? 400 : 'auto'
    }}
    >
    <Autocomplete
    options={clients}
    getOptionLabel={(option) => option.NOMBRE}
    value={selectedClient}
    onChange={(event, value) => setSelectedClient(value)}
    renderOption={(props, option) => (
      <li {...props} key={option.ID}>
      {option.NOMBRE}
      </li>
    )}
    renderInput={(params) => (
      <TextField
      {...params}
      label="Selecciona un cliente"
      variant="outlined"
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor: 'common.white',
          borderRadius: 2,
          height: 56
        }
      }}
      />
    )}
    />

    {selectedClient && (
      <>
      {isLoading ? (
        <div style={{ 
          display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            height: "400px",
            width: '100%'
        }}>
        <CircularProgress />
        </div>
      ) : (
        <>
        <Card
        sx={{
          width: '100%',
            boxShadow: (theme) => theme.shadows[3],
            minHeight: 200
        }}
        >
        <CardHeader
        title="Configuración del Horario"
        sx={{
          borderBottom: 1,
            borderColor: 'divider',
            background: '#041562',
            color: 'common.white',
            padding: { xs: 2, sm: 3 },
            height: { xs: 64, sm: 72 },
            '& .MuiCardHeader-title': {
              fontSize: { xs: '1.25rem', sm: '1.5rem' },
              fontWeight: 600
            }
        }}
        />
        <CardContent
        sx={{
          padding: { xs: 2, sm: 3 },
            minHeight: 300,
            '& .MuiGrid-container': {
              margin: 0
            }
        }}
        >
        <Grid 
        container 
        spacing={{ xs: 2, sm: 3 }}
        sx={{
          '& .MuiGrid-item': {
            width: '100%'
          }
        }}
        >
        {Object.entries(schedule).map(([day, selectedHours]) => (
          <DaySchedule
          key={day}
          day={day}
          selectedHours={selectedHours}
          onHourSelect={handleHourSelect}
          onDelete={handleDelete}
          />
        ))}
        </Grid>
        {isCompleted.readyToSave && (
          <Button
          variant="contained"
          fullWidth
          disabled={btnDisabled}
          sx={{
            mt: 4,
              py: 1.5,
              fontSize: '1.1rem',
              fontWeight: 600,
              borderRadius: 2,
              background: '#041562',
              height: 48
          }}
          onClick={handleSave}
          >
          Guardar horario
          </Button>
        )}
        </CardContent>
        </Card>

        <Paper
        elevation={3}
        sx={{
          padding: { xs: 2, sm: 3 },
            display: (isCompleted.horario) ? "flex" : "none",
            flexDirection: "column",
            minHeight: { xs: 200, sm: 200 },
            width: '100%',
            overflowY: 'auto',
            borderRadius: 2,
            backgroundColor: 'common.white',
            '&::-webkit-scrollbar': {
              width: '8px'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: (theme) => theme.palette.grey[100],
              borderRadius: '4px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: (theme) => theme.palette.grey[400],
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.grey[600]
              }
            }
        }}
        >
        {(isCompleted.horario) && (
          <Typography 
          variant="h6" 
          sx={{
            mb: 2,
              fontSize: { xs: '1.25rem', sm: '1.5rem' },
              fontWeight: 600,
              color: (theme) => theme.palette.text.primary,
              height: 40
          }}
          >
          Seleccione destinatarios
          </Typography>
        )}
        <FormGroup sx={{ flexGrow: 1 }}>

        <Grid 
        container 
        spacing={2} 
        sx={{
          display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" }, // Columnas en md o menor, filas en lg+
            flexWrap: "wrap",
        }}
        >
        {destinatariosYaAsociados.map(({ EMAIL }, i) => (
          <Grid 
          item 
          xs={12} 
          sm={12} 
          md={12} 
          lg={4} // Solo en lg+ se reparten en 3 columnas
          key={i}
          sx={{ display: "flex", justifyContent: "center" }}
          >
          <Tooltip title="Click para seleccionar un destinatario" arrow placement="top">
          <FormControlLabel
          control={
           <Checkbox
            checked={!!selectedDestinatarios[EMAIL]}
            onChange={handleChange}
            name={EMAIL}
            sx={{
              "&.Mui-checked": {
                color: (theme) => theme.palette.primary.main,
              },
                height: 20,
                wordBreak: "break-word"
            }}
            />
          }
          label={EMAIL}
          sx={{
            textAlign: "center",
              width: "100%",
 wordBreak: "break-word",
              flex: 1,
              margin: 0,
              padding: 1,
              borderRadius: 1,
              minHeight: 20,
              "& .MuiFormControlLabel-label": {
                width: "100%",
                fontSize: { xs: "0.875rem", sm: "1rem" },
              },
          }}
          />
          </Tooltip>
          </Grid>
        ))}
        </Grid>

        <Button 
        onClick={handleOpen}
        sx={{
          height: 48,
            mt:"auto"
        }}
        >
        Agregar destinatarios
        </Button>

        <div>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box
        sx={{
          position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            minHeight: 200
        }}
        >
        <Typography 
        id="modal-modal-title" 
        variant="h6" 
        component="h2"
        sx={{ mb: 2, height: 32 }}
        >
        Busque o agregué un destinatario.
        </Typography>

        <Autocomplete
        options={destinatariosAsignablesPorCliente}
        id="add-destinatario"
        freeSolo
        getOptionLabel={(option) => typeof option === "string" ? option : option.EMAIL || ""}
        onChange={(event, value) => {
          if (typeof value === "string") {
            if(!value.includes("@")){
              setInputDestinatarioError("Error porfavor introduzca un correo valido.")
              return
            } else {
              if(!value.substring(value.indexOf("@") + 1, value.length).includes(".")){
                setInputDestinatarioError("Error porfavor introduzca un fin de correo valido.")
                return
              }
            }
            setInputDestinatarioError(null)
            setDestinatariosYaAsociados((prev) => {
              if (!prev.some(dest => dest.EMAIL === value)) {
                return [...prev, { EMAIL: value}];
              }
              return prev;
            });

            setTimeout(() => {
              setOpen(false)            
            }, 100)
          } else if (value && value.EMAIL) {
            setDestinatariosYaAsociados((prev) => {
              if (!prev.some(item => item.EMAIL === value.EMAIL)) {
                return [...prev, value];
              }
              return prev;
            });

            setTimeout(() => {
              setOpen(false)            
            }, 100)
          }
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.EMAIL}>
          {option.EMAIL}
          </li>
        )}
        renderInput={(params) => (
          <TextField
          {...params}
          label="Selecciona un destinatario"
          variant="outlined"
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "common.white",
              borderRadius: 2,
              height: 56,
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: "translateY(-1px)",
                boxShadow: (theme) => theme.shadows[2],
              },
            },
          }}
          />
        )}
        />
        {inputDestinatarioError && (
          <Typography 
          sx={{ 
            color: "#DA251C",
              height: 24,
              mt: 1
          }}
          >
          {inputDestinatarioError}
          </Typography>
        )}
        <Button
        variant="contained"
        fullWidth
        disabled={btnDisabled}
        sx={{
          mt: 4,
            py: 1.5,
            fontSize: '1.1rem',
            fontWeight: 600,
            borderRadius: 2,
            background: '#041562',
            height: 48,
            transition: 'transform 0.2s, box-shadow 0.2s',
            '&:hover': {
              transform: 'translateY(-1px)',
              boxShadow: (theme) => theme.shadows[4]
            }
        }}
        onClick={async () => {
          const inputElement = document.getElementById("add-destinatario");
          const value = inputElement.value
          if(!value.includes("@")){
            setInputDestinatarioError("Error porfavor introduzca un correo valido.")
            return
          } else {
            if(!value.substring(value.indexOf("@") + 1, value.length).includes(".")){
              setInputDestinatarioError("Error porfavor introduzca un fin de correo valido.")
              return
            }
          }

          setDestinatariosYaAsociados((prev) => {
            if (!prev.some(item => item.EMAIL === inputElement.value)) {
              return [...prev,{EMAIL:  inputElement.value}];
            }
            return prev;
          });

          setTimeout(() => {
            setOpen(false)            
          }, 100)
        }}
        >
        Guardar destinatario
        </Button>
        </Box>
        </Modal>
        </div>
        </FormGroup>
        </Paper>
        {(reportesYaAsociados.length > 0) && (
          <>
          <Button
          variant="contained" 
          color="error" 
          startIcon={<DeleteIcon />} 
          onClick={() => setOpenConfirm(true)}
          sx={{
            height: 30
          }}
          >
          Eliminar reporte ya asignado
          </Button>

          <DeleteConfirmDialog 
          open={openConfirm} 
          onClose={() => setOpenConfirm(false)} 
          onConfirm={() => {handleDeleteButton(selectedClient.ID)}} 
          />
          </>
        )}
        </>
      )}
      </>
    )}
    </Box>
    </Box>
    </>
  );;
};

export default ReporteAutomaticoCliente;

