import React, { memo, useState } from "react";
import SalidaRuta from "./SalidaRuta";
import SaldiaRutaPorComuna from "./SalidaRutaRetirosPorComuna";
import SalidaRutaRetirosPorOd from "./SalidaRutaRetirosPorOd";
import GestorManifiestoRuta from "./GestorManifiestoRuta";

import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const ListadosManifiestos = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const tipo_usuario = userInfo ? userInfo.tipo_usuario : null;
  const [selectedModule, setSelectedModule] = useState("");

  const renderModule = () => {
    switch (selectedModule) {
      case "ManifiestosRuta":
        return <SalidaRuta />;
      case "GestorManifiestoRuta":
        return <GestorManifiestoRuta />;
      case "ManifiestoComuna":
        return <SaldiaRutaPorComuna />;
      case "ManifiestoRutaPorOd":
        return <SalidaRutaRetirosPorOd />;
      default:
        return <Typography variant="h6">Seleccione un módulo para mostrar</Typography>;
    }
  };

  return (
    <Box p={1}>
      <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
        <Button
          onClick={() => setSelectedModule("ManifiestosRuta")}
          sx={{
            backgroundColor: selectedModule === "ManifiestosRuta" ? "#041562" : undefined,
            color: selectedModule === "ManifiestosRuta" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "ManifiestosRuta" ? "#DA251C" : undefined,
            },
          }}
        >
          Manifiesto Ruta
        </Button>

        <Button
          onClick={() => setSelectedModule("GestorManifiestoRuta")}
          sx={{
            backgroundColor: selectedModule === "GestorManifiestoRuta" ? "#041562" : undefined,
            color: selectedModule === "GestorManifiestoRuta" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "GestorManifiestoRuta" ? "#DA251C" : undefined,
            },
          }}
        >
          Edicion Manifiesto Ruta
        </Button>

        <Button
          onClick={() => setSelectedModule("ManifiestoComuna")}
          sx={{
            backgroundColor: selectedModule === "ManifiestoComuna" ? "#041562" : undefined,
            color: selectedModule === "ManifiestoComuna" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "ManifiestoComuna" ? "#DA251C" : undefined,
            },
          }}
        >
          Manifiesto Retiros Por Comuna
        </Button>

        <Button
          onClick={() => setSelectedModule("ManifiestoRutaPorOd")}
          sx={{
            backgroundColor: selectedModule === "ManifiestoRutaPorOd" ? "#041562" : undefined,
            color: selectedModule === "ManifiestoRutaPorOd" ? "#fff" : undefined,
            "&:hover": {
              backgroundColor: selectedModule === "ManifiestoRutaPorOd" ? "#DA251C" : undefined,
            },
          }}
        >
          Manifiesto Retiros Por Orden
        </Button>
      </ButtonGroup>
      <Box mt={1}>{renderModule()}</Box>
    </Box>
  );
};

export default memo(ListadosManifiestos);
