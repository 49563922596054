import axios from "axios";
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const ChoferesPorAgente = async (agente) => {
  try {
    const response = await axios.post(`${BASE_API_URL}consolidado/ChoferesPorAgente`, {
      agente,
    });
    if (response.data.length === 0) {
      return null;
    } else {
      return response.data;
    }
  } catch (error) {
    console.error("Error obteniendo choferes desde Back", error);
    throw error;
  }
};


export const obtenerOrdenesPendientesChofer = async (codChofer) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}consolidado/obtenerOrdenesPendientesChofer`,
      { codChofer }
    );

    return response.data;
  } catch (error) {
    console.error("Error obteniendo órdenes pendientes para chofer:", error);
    throw error;
  }
};
