import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Grid, TextField, Autocomplete, Typography, Paper, CircularProgress } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import useSound from "use-sound";
import sonido1 from "../../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/AlertaMa.mp3";
import sonido2 from "../../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/Alerta Manifiesto.mp3";
import * as XLSX from "xlsx";
import {
  buscarDatosOrden,
  insertPickingTemp,
  deletePickingTemp,
  createManifiestoAndPicking,
  buscarDatosPickingTemp,
  buscarManifiestosxId,
  buscarManifiestosxOd,
  buscarCiudades,
  downloadManifestPdfGlobal,
} from "../../../../services/ManifiestosServices";
import { editarBultos } from "../../../../services/ManifiestosServices";
import ModalComponent from "../../componentesManifiestoRetorno/Modal";
import Swal from "sweetalert2";
import TablaComparativa from "../../componentesManifiestoEntradaSantiago/TablaComparativa";
import { useSelector } from "react-redux";
import TablaManifiestos from "../TablaManifiestos";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";

function IngresoSantiago() {
  const inputRef = useRef(null);
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const iataUser = userInfo ? userInfo.iata_agentes : null;
  const ciudadUser = userInfo ? userInfo.nombre_ciudad_agentes : null;
  const nombreUser = userInfo ? userInfo.name : null;
  const [proceso, setProceso] = useState("");
  const [fechaActual, setfechaActual] = useState("");
  const [nota, setNota] = useState("");
  const [comunaRuta, setComunaRuta] = useState("");
  const [ciudadesOptions, setCiudadesOptions] = useState([]);
  const [ciudad, setCiudad] = useState(null);
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [totalBultos, setTotalBultos] = useState(0);
  const [totalOrdenes, setTotalOrdenes] = useState(0);
  const [ordenesDataTablaM, setOrdenesDataTablaM] = useState([]);
  const [ordenesPendientes, setOrdenesPendientes] = useState();
  const [playsound] = useSound(sonido1);
  const [playsoundManifiesto, { stop: stopManifiesto }] = useSound(sonido2, {});
  const [loadingEliminar, setLoadingEliminar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isbtnEnviar, setIsbtnEnviar] = useState(false);
  const [open, setOpen] = useState(false);
  const [idToUpdate, setIdToUpdate] = useState(null);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [selectedManifestIds, setSelectedManifestIds] = useState(new Set());

  useEffect(() => {
    const fetchData = async () => {
      setProceso("INGRESOSANTIAGO");

      try {
        const fechaActual = moment().format("YYYY-MM-DD");
        console.log(fechaActual);
        setfechaActual(fechaActual);
        const ciudades = await buscarCiudades();
        setCiudadesOptions(ciudades.map((ciudades) => ciudades.NOMBRE));

        const ciudadesData = await buscarCiudades();
        const ciudadesOptions = ciudadesData.map((ciudades) => ({
          value: ciudades.IATA,
          label: ciudades.NOMBRE,
        }));
        setCiudadesOptions(ciudadesOptions);
      } catch {}
    };
    fetchData();
  }, [id_user]);

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: message,
    });
    playsound();
    inputRef.current.value = "";
  };

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE", headerName: "Cliente", width: 250 },
    { field: "BULTOS", headerName: "Bultos", width: 80 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 100 },
    { field: "NOMBRE_DESTINO", headerName: "Comuna", width: 120 },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon style={{ color: "#DA251C", cursor: "pointer" }} onClick={() => handleDelete(params.row.OD)} />
      ),
    },
    {
      field: "edit",
      headerName: "Editar",
      width: 80,
      renderCell: (params) => (
        <EditIcon style={{ color: "#041562", cursor: "pointer" }} onClick={() => handleEdit(params.row.ID)} />
      ),
    },
  ];

  const handleEdit = async (id) => {
    handleOpen();
    setIdToUpdate(id);
  };

  /* borrar ordenes de la tabla y también de la base de datos */
  const handleDelete = async (orden) => {
    setLoadingEliminar(true);
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    });

    if (result.isConfirmed) {
      try {
        const response = await deletePickingTemp({
          OD: orden,
          COD_USUARIO: id_user,
          PROCESO: proceso,
        });

        if (response.message === "PickingTemp Eliminado con éxito") {
          const datos = {
            COD_USUARIO: id_user,
            COMUNA: comunaRuta,
            PROCESO: proceso,
            AGENTE: "BODEGA SANTIAGO",
          };
          try {
            const resultados = await buscarDatosPickingTemp(datos);

            let totalBultosTemp = 0;
            if (resultados && resultados.results) {
              resultados.results.forEach((orden) => {
                totalBultosTemp += orden.BULTOS;
              });
              setTotalOrdenes(resultados.results.length);
            }

            setTotalBultos(totalBultosTemp);

            if (resultados && Array.isArray(resultados.results)) {
              setOrdenesDataTabla(resultados.results);
            } else {
              setOrdenesDataTabla([]);
            }

            const idsSeleccionados = Array.from(selectedManifestIds);

            if (idsSeleccionados.length > 0) {
              const datap = await buscarManifiestosxId(idsSeleccionados);
              setOrdenesDataTablaM(datap);
              setOrdenesPendientes(datap.length);
            } else {
              console.log("No hay Ids Manifiesto");
            }
          } catch (error) {
            console.error("Error al buscar datos de picking temp:", error);
            setOrdenesDataTabla([]);
          }

          Swal.fire("Eliminado!", "El registro ha sido eliminado.", "success");
        } else {
          Swal.fire("No se pudo eliminar", response.message, "error");
        }
      } catch (error) {
        console.error("Error al eliminar el picking temp:", error);
        Swal.fire("Error!", "No se pudo eliminar el registro debido a un error en el servidor.", "error");
      } finally {
        setLoadingEliminar(false);
      }
    }
    setLoadingEliminar(false);
  };

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      const options = {
        timeZone: "America/Santiago",
        hour12: false,
      };

      const fecha = fechaActual.toLocaleDateString("es-CL", options);

      const [dia, mes, año] = fecha.split("-");

      const nuevaFecha = `${año}-${mes}-${dia}`;

      const hora = fechaActual.toLocaleTimeString("es-CL", {
        ...options,
        hour12: false,
      });

      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;

      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null;
    }
  };

  const resetState = () => {
    const fechaActual = moment().format("YYYY-MM-DD");
    setfechaActual(fechaActual);
    setNota("");
    inputRef.current.value = "";
    setOrdenesDataTabla([]);
    setTotalBultos(0);
    setTotalOrdenes(0);
    setOrdenesDataTablaM([]);
    setCiudad(null);
    setSelectedManifestIds(new Set());
    setIsbtnEnviar(false);
    setLoading(false);
    setOrdenesPendientes(0);
  };

  const handleChangeCiudades = async (event, newValue) => {
    try {
      const codi = newValue.value;
      setCiudad(newValue);
      setComunaRuta(newValue.value);

      const proceso = "INGRESOSANTIAGO";
      const data = {
        COD_USUARIO: id_user,
        COMUNA: codi,
        PROCESO: proceso,
        AGENTE: "BODEGA SANTIAGO",
      };
      const dataPickingTemp = await buscarDatosPickingTemp(data);

      let totalBultosTemp = 0;
      if (dataPickingTemp && dataPickingTemp.results) {
        dataPickingTemp.results.forEach((orden) => {
          totalBultosTemp += orden.BULTOS;
        });
        setTotalOrdenes(dataPickingTemp.results.length);
      }

      setTotalBultos(totalBultosTemp);

      if (dataPickingTemp && dataPickingTemp.results) {
        setOrdenesDataTabla(dataPickingTemp.results);
      } else {
        setOrdenesDataTabla([]);
      }
    } catch {}
  };

  const handleChangeNota = (event) => {
    setNota(event.target.value);
  };

  const agregarManifiesto = (id) => {
    setSelectedManifestIds((prevIds) => {
      const newIds = new Set(prevIds);
      newIds.add(id);
      buscarManifiestosxId(Array.from(newIds))
        .then((datap) => {
          setOrdenesDataTablaM(datap);
          setOrdenesPendientes(datap.length);
        })
        .catch((error) => {
          console.error("Error al buscar manifiestos por ID:", error);
        });

      return newIds;
    });
  };

  const ingresarOrdenTabla = async (dataOrden) => {
    try {
      if (dataOrden && dataOrden.length > 0) {
        try {
          setOrdenesDataTabla([...ordenesDataTabla, dataOrden[0]]);
          setTotalBultos(totalBultos + dataOrden[0].BULTOS);
          setTotalOrdenes(totalOrdenes + 1);

          const dataPendiente = await buscarManifiestosxOd(dataOrden[0].OD);

          await insertPickingTemp({
            OD: dataOrden[0].OD,
            COD_USUARIO: id_user,
            COD_CHOFER: id_user,
            COMUNA: comunaRuta,
            PROCESO: proceso,
            AGENTE: "BODEGA SANTIAGO",
          });

          if (dataPendiente.length === 0) {
            console.log("No tiene manifiesto de Salida Agente");
          } else {
            dataPendiente.forEach((item) => {
              agregarManifiesto(item.ID_MANIFIESTO);
            });
          }
        } catch (error) {
          console.error("Error 12345:", error);
        }
        inputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const ordenIngresada = useCallback(
    async (event) => {
      event.preventDefault();
      const ordenValue = inputRef.current.value.trim();

      try {
        if (!comunaRuta) {
          Swal.fire({
            icon: "error",
            title: "Debe Seleccionar una Comuna!!!",
          });
          inputRef.current.value = "";
          playsound();
          return;
        } else {
          try {
            const dataOrden = await buscarDatosOrden(ordenValue);
            if (dataOrden && dataOrden.length > 0) {
              const ordenExistente = ordenesDataTabla.some((orden) => orden.ID === dataOrden[0].ID);

              if (ordenExistente) {
                showError("Orden ya ingresada al manifiesto!!!");
                return;
              }

              if (
                comunaRuta !== dataOrden[0].IATA_PADRE_COMUNA_ORIGEN &&
                comunaRuta !== dataOrden[0].IATA_PADRE_COMUNA_DESTINO
              ) {
                Swal.fire({
                  icon: "error",
                  title: "Orden no Pertenece a Ciudad!!!",
                });
                playsound();
                inputRef.current.value = "";
                return;
              } else {
                try {
                  ingresarOrdenTabla(dataOrden);
                } catch {}
              }

              inputRef.current.value = "";
            } else {
              Swal.fire({
                icon: "error",
                title: "Orden no existe!!!",
              });
              playsound();
              inputRef.current.value = "";
              return;
            }
          } catch (error) {
            console.error("Error:", error);
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comunaRuta, ordenesDataTabla, playsound],
  );

  const crearManifeisto = async () => {
    try {
      const fecha = await getFecha(); // Obtén la fecha actual
  
      // Datos para crear manifiesto y picking
      const data = {
        FH_CREACION: fecha,
        FH_CIERRE: fecha,
        COD_USUARIO: id_user,
        PROCESO: proceso,
        ID_CLIENTE: 0,
        COD_CHOFER: id_user,
        ESTADO: "CERRADO",
        COD_OPERADOR_ENTRADA: id_user,
        COD_CHOFER_ENTRADA: id_user,
        NOMBRE_CHOFER_ENTRADA: nombreUser,
        SELLOS: "",
        CANTIDAD_VALIJAS: 0,
        TOTAL_BULTOS: totalBultos,
        TIPO_RUTA: "TERRESTRE",
        TIPO_MANIFIESTO: proceso,
        ORIGEN: comunaRuta,
        DESTINO: iataUser,
        NOTA: nota,
        FH_MAIL: "",
        RUTA: comunaRuta,
        CIUDAD: ciudad.value,
      };
  
      // Llama a la nueva función combinada
      const numeroManifiesto = await createManifiestoAndPicking(data);
  
      // Limpieza de estados y notificación de éxito
      setOrdenesDataTabla([]); 
      setTotalBultos(""); 
      toast.success(`Manifiesto creado con éxito. N°: ${numeroManifiesto}`);
  
      return numeroManifiesto;
    } catch (error) {
      // Manejo de errores
      console.error("Error al crear el manifiesto y picking:", error);
      toast.error("Error al crear el manifiesto. Por favor, intente nuevamente.");
    }
  };
  

  const handleCrearManifiesto = async () => {
    setLoading(true);
    setIsbtnEnviar(true);
    setLoadingEliminar(true);

    console.log("Boton", isbtnEnviar);
    try {
      const idsSeleccionados = Array.from(selectedManifestIds);

      let datap = [];

      if (idsSeleccionados.length > 0) {
        datap = await buscarManifiestosxId(idsSeleccionados);
        setOrdenesDataTablaM(datap);
        setOrdenesPendientes(datap.length);
      } else {
        console.log("No hay Ids Manifiesto");
      }

      if (!comunaRuta) {
        Swal.fire({
          icon: "error",
          title: "Debe selecciona una comuna antes de continuar!!!",
        });
        inputRef.current.value = "";
        playsound();
        setLoading(false);
        setIsbtnEnviar(false);
        setLoadingEliminar(false);
        return;
      } else if (ordenesDataTabla.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Manifiesto debe contener alguna orden!!!",
        });
        playsound();
        setLoading(false);
        setIsbtnEnviar(false);
        setLoadingEliminar(false);
        return;
      } else if (datap.length > 0) {
        Swal.fire({
          title: "Aun hay pendientes por Manifestar?",
          text: "Esta a punto de crear un nuevo Manifiesto!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Crear!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              stopManifiesto();
              const numeroManifiesto = await crearManifeisto();
              setLoading(false);
              setLoadingEliminar(false);
              setLoading(false);
              resetState();
              Swal.fire({
                title: "Manifiesto Creado",
                text: "N°: " + numeroManifiesto,
                icon: "success",
              });
              try {
                await downloadManifestPdfGlobal(numeroManifiesto, proceso, ciudad.label, "", false);
              } catch (error) {
                Swal.fire({
                  icon: "error",
                  title: "Error al descargar el PDF del manifiesto",
                });
              }
            } finally {
              setLoading(false);
              setIsbtnEnviar(false);
              setLoadingEliminar(false);
              stopManifiesto();
            }
          } else {
            setLoading(false);
            setIsbtnEnviar(false);
            setLoadingEliminar(false);
            stopManifiesto();
          }
        });
        playsoundManifiesto();
      } else {
        Swal.fire({
          title: "Seguro de Crear Manifiesto?",
          text: "Esta a punto de crear un nuevo Manifiesto!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Crear!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const numeroManifiesto = await crearManifeisto();
              setLoading(false);
              setLoadingEliminar(false);
              setLoading(false);
              resetState();
              Swal.fire({
                title: "Manifiesto Creado",
                text: "N°: " + numeroManifiesto,
                icon: "success",
              });
              try {
                await downloadManifestPdfGlobal(numeroManifiesto, proceso, ciudad.label, "", false);
              } catch (error) {
                Swal.fire({
                  icon: "error",
                  title: "Error al descargar el PDF del manifiesto",
                });
              }
            } finally {
              setLoading(false);
              setIsbtnEnviar(false);
              setLoadingEliminar(false);
            }
          } else {
            setLoading(false);
            setIsbtnEnviar(false);
            setLoadingEliminar(false);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      setIsbtnEnviar(false);
      setLoadingEliminar(false);
      Swal.fire({
        icon: "error",
        title: "Error al crear el manifiesto",
      });
    }
  };

  const downloadExcel = () => {
    const filteredData = ordenesDataTabla.map((item) => ({
      CLIENTE: item.NOMBRE,
      OD_PAPEL: item.OD,
      BULTOS: item.BULTOS,
      PESO: item.PESO,
      ALTO: item.ALTO,
      LARGO: item.LARGO,
      ANCHO: item.ANCHO,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Planilla");
    XLSX.writeFile(workbook, "Ordenes_Palnilla.xlsx");
  };

  const handleUpdate = async (id, nuevosBultos) => {
    try {
      const ordenToUpdate = ordenesDataTabla.find((orden) => orden.ID === id);
      if (!ordenToUpdate) {
        console.error("No se encontró la orden con el ID especificado");
        return;
      }

      await editarBultos({
        OD: ordenToUpdate.OD,
        BULTOS: nuevosBultos,
        USUARIO: id_user,
      });

      console.log("Datos de la orden actualizados correctamente en el backend");

      const updatedOrdenesDataGrid = ordenesDataTabla.map((orden) =>
        orden.ID === id ? { ...orden, BULTOS: nuevosBultos } : orden,
      );
      setOrdenesDataTabla(updatedOrdenesDataGrid);

      const bultosAnteriores = ordenToUpdate.BULTOS || 0;
      const bultosActualizados = nuevosBultos || 0;
      const cambioBultos = bultosActualizados - bultosAnteriores;

      setTotalBultos(totalBultos + cambioBultos);
    } catch (error) {
      console.error("Error al actualizar datos de la orden en el backend:", error);
    }
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                disableClearable
                value={ciudad}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={ciudadesOptions}
                onChange={handleChangeCiudades}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => <Paper sx={{ width: "100%", textAlign: "left" }}>{children}</Paper>}
                renderInput={(params) => <TextField {...params} label="CIUDAD ORIGEN" />}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                label="CIUDAD DESTINO"
                value={ciudadUser}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                name="FH_ACTUAL"
                label="Fecha"
                sx={{ width: "100%" }}
                value={fechaActual}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                size="small"
                id="outlined-size-small"
                fullWidth
                label="Nota"
                multiline
                rows={1}
                name="message"
                value={nota}
                onChange={handleChangeNota}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} justifyContent="flex-end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  width: "100%",
                  height: "90%",
                  backgroundColor: "#041562",
                }}
                onClick={handleCrearManifiesto}
                disabled={isbtnEnviar}
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper style={{ padding: "10px", marginTop: "10px" }}>
        <form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Ingrese OD"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    ordenIngresada(e);
                  }
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                ORDENES: {totalOrdenes}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                BULTOS: {totalBultos}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={1} md={1} lg={1}>
              <Button
                variant="contained"
                onClick={downloadExcel}
                sx={{ maxHeight: 40, backgroundColor: "#217346", color: "white" }}
              >
                {<FileDownloadIcon />}
                EXCEL
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8} md={8} lg={8} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <TablaManifiestos rows={ordenesDataTabla} columns={columns} loading={loadingEliminar} />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <TablaComparativa ordenesDataTablaM={ordenesDataTablaM} pendientes={ordenesPendientes} />
            </Grid>
          </Grid>
        </form>
        <ModalComponent
          open={open}
          handleClose={handleClose}
          handleUpdate={(bultos) => handleUpdate(idToUpdate, bultos)}
        />
      </Paper>
    </div>
  );
}
export default IngresoSantiago;
