import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Grid, TextField, Autocomplete, Typography, Paper, CircularProgress } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import useSound from "use-sound";
import sonido1 from "../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/AlertaMa.mp3";
import {
  buscarTransportistas,
  insertPickingTemp,
  insertManifiesto,
  deletePickingTemp,
  insertPicking,
  buscarDatosPickingTempRetornos,
  buscarCiudades,
  downloadManifestPdfGlobal,
} from "../../../services/ManifiestosServices";
import { getOrdenIdReferencia } from "../../../services/ManifiestosServices";
import Swal from "sweetalert2";
import {
  Mail_Gestor,
  Mail_Gestor_Sup,
} from "../../../components/OperacionesComponentes/componentesManifiestoRetorno/Mail";
import { useSelector } from "react-redux";
import TablaManifiestos from "../../../components/OperacionesComponentes/componentesManifiestos/TablaManifiestos";
import DeleteIcon from "@mui/icons-material/Delete";

const ManifiestosRetornoRef = () => {
  const inputRef = useRef(null);
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const ciudadUser = userInfo ? userInfo.nombre_ciudad_agentes : null;
  const nombreUser = userInfo ? userInfo.name : null;
  const iataUser = userInfo ? userInfo.iata_agentes : null;
  const correoUser = userInfo ? userInfo.email : null;

  const [proceso, setProceso] = useState("");
  const [fechaActual, setfechaActual] = useState("");
  const [nota, setNota] = useState("");
  const [sellos, setSellos] = useState("");
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [totalBultos, setTotalBultos] = useState(0);
  const [totalOrdenes, setTotalOrdenes] = useState(0);
  const [playsound] = useSound(sonido1);
  const [ciudades, setCiudades] = useState([]);
  const [iataCiudad, setIataCiudad] = useState("");
  const [selectedCiudad, setSelectedCiudad] = useState(null);
  const [selectedTransportes, setSelectedTransportes] = useState(null);
  const [transportes, setTransportes] = useState([]);
  const [idTransporte, setIdTransporte] = useState("");
  const [loadingEliminar, setLoadingEliminar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isbtnEnviar, setIsbtnEnviar] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");

  useEffect(() => {
    const Clientes = async () => {
      try {
        const clientesData = [{ label: "NOVOFARMA SERVICE S.A.", value: 632 }];

        setSelectedCliente(clientesData[0].label);
        setIdCliente(clientesData[0].value);
        setClientes(clientesData);
      } catch {}
    };
    Clientes();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setProceso("SALIDAAGENTE");
      try {
        const fechaActual = moment().format("YYYY-MM-DD");
        setfechaActual(fechaActual);

        const ciudadesData = await buscarCiudades();
        const ciudadesOptions = ciudadesData.map((ciudades) => ({
          value: ciudades.IATA,
          label: ciudades.NOMBRE,
        }));
        setCiudades(ciudadesOptions);

        const transporteData = await buscarTransportistas();
        const transportesOptions = transporteData.map((transportes) => ({
          value: transportes.COD_CHOFER,
          label: transportes.NOMBRE,
        }));
        setTransportes(transportesOptions);
      } catch {}
    };
    fetchData();
  }, [id_user]);

  const columns = [
    { field: "COD_BARRA", headerName: "COD. BARRA", width: 100 },
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE", headerName: "Cliente", width: 400 },
    { field: "BULTOS", headerName: "Bultos", width: 100 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 200 },
    { field: "NOMBRE_DESTINO", headerName: "Comuna", width: 200 },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 80,
      renderCell: (params) => (
        <DeleteIcon style={{ color: "#DA251C", cursor: "pointer" }} onClick={() => handleDelete(params.row.OD)} />
      ),
    },
  ];

  const handleDelete = async (orden) => {
    setLoadingEliminar(true);
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    });

    if (result.isConfirmed) {
      try {
        const response = await deletePickingTemp({
          OD: orden,
          COD_USUARIO: id_user,
          PROCESO: proceso,
        });

        if (response.message === "PickingTemp Eliminado con éxito") {
          const datos = {
            COD_USUARIO: id_user,
            PROCESO: proceso,
            COMUNA: iataCiudad,
          };
          try {
            const resultados = await buscarDatosPickingTempRetornos(datos);

            let totalBultosTemp = 0;
            if (resultados && resultados.results) {
              resultados.results.forEach((orden) => {
                totalBultosTemp += orden.BULTOS;
              });
              setTotalOrdenes(resultados.results.length);
            }

            setTotalBultos(totalBultosTemp);

            if (resultados && Array.isArray(resultados.results)) {
              setOrdenesDataTabla(resultados.results);
            } else {
              setOrdenesDataTabla([]);
            }
          } catch (error) {
            console.error("Error al buscar datos de picking temp:", error);
            setOrdenesDataTabla([]);
          }

          Swal.fire("Eliminado!", "El registro ha sido eliminado.", "success");
        } else {
          Swal.fire("No se pudo eliminar", response.message, "error");
        }
      } catch (error) {
        console.error("Error al eliminar el picking temp:", error);
        Swal.fire("Error!", "No se pudo eliminar el registro debido a un error en el servidor.", "error");
      } finally {
        setLoadingEliminar(false);
      }
    }
    setLoadingEliminar(false);
  };

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: message,
    });
    playsound();
    inputRef.current.value = "";
  };

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      const options = {
        timeZone: "America/Santiago",
        hour12: false,
      };

      const fecha = fechaActual.toLocaleDateString("es-CL", options);

      const [dia, mes, año] = fecha.split("-");

      const nuevaFecha = `${año}-${mes}-${dia}`;

      const hora = fechaActual.toLocaleTimeString("es-CL", {
        ...options,
        hour12: false,
      });

      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;

      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null;
    }
  };

  const resetState = () => {
    setNota("");
    setSellos("");
    inputRef.current.value = "";
    setOrdenesDataTabla([]);
    setTotalBultos(0);
    setIdTransporte("");
    const fechaActual = moment().format("YYYY-MM-DD");
    setfechaActual(fechaActual);
    setTotalOrdenes(0);
    setSelectedCiudad("");
    setSelectedTransportes("");
    setLoading(false);
    setIsbtnEnviar(false);
  };

  const handleChangeClientes = async (event, newValue) => {
    try {
      setSelectedCliente(newValue);
      setIdCliente(newValue.value);
    } catch {}
  };

  const handleChangeCiudades = async (event, newValue) => {
    try {
      setSelectedCiudad(newValue);
      const codi = newValue.value;
      setIataCiudad(codi);

      const datos = {
        COD_USUARIO: id_user,
        PROCESO: proceso,
        COMUNA: codi,
      };
      const dataPickingTemp = await buscarDatosPickingTempRetornos(datos);

      let totalBultosTemp = 0;
      if (dataPickingTemp && dataPickingTemp.results) {
        dataPickingTemp.results.forEach((orden) => {
          totalBultosTemp += orden.BULTOS;
        });
        setTotalOrdenes(dataPickingTemp.results.length);
      }

      setTotalBultos(totalBultosTemp);

      if (dataPickingTemp && dataPickingTemp.results) {
        setOrdenesDataTabla(dataPickingTemp.results);
      } else {
        setOrdenesDataTabla([]);
      }
    } catch (error) {
      console.error("Error al obtener datos del backend:", error);
    }
  };

  const handleChangeTransportes = async (event, newValue) => {
    try {
      setSelectedTransportes(newValue);
      setIdTransporte(newValue.value);
    } catch (error) {
      console.error("Error al obtener datos del backend:", error);
    }
  };

  const handleChangeSellos = (event) => {
    setSellos(event.target.value);
  };

  const handleChangeNota = (event) => {
    setNota(event.target.value);
  };

  const ordenIngresada = useCallback(
    async (event) => {
      event.preventDefault();
      const ordenValue = inputRef.current.value.trim();
      try {
        if (!idCliente) {
          showError("Debe Seleccionar un Cliente!!!");
          return;
        }

        if (!iataCiudad) {
          showError("Debe Seleccionar un Destino!!!");
          return;
        }

        if (!idTransporte) {
          showError("Debe seleccionar un transporte!!!");
          return;
        }

        const datos = {
          COD_CLIENTE: idCliente,
          COD_BARRA: ordenValue,
        };
        const dataOrden = await getOrdenIdReferencia(datos);

        if (!dataOrden || dataOrden.length === 0) {
          showError("No se encontro orden con el codigo entregado para el cliente seleccionado!!!");
          return;
        }

        const ordenExistente = ordenesDataTabla.some((orden) => orden.ID === dataOrden[0].ID);

        if (ordenExistente) {
          showError("Orden ya ingresada al manifiesto!!!");
          return;
        }

        if (
          (dataOrden[0].TIPO_ORDEN === "ENTREGA" && iataCiudad === dataOrden[0].IATA_PADRE_COMUNA_DESTINO) ||
          (dataOrden[0].TIPO_ORDEN === "RETIRO" && iataCiudad === dataOrden[0].IATA_PADRE_COMUNA_DESTINO)
        ) {
          ingresarOrdenTabla(dataOrden);
          inputRef.current.value = "";
          return;
        } else {
          playsound();
          Swal.fire({
            title: "Comuna diferente",
            text: "La comuna de la orden no coincide con la ciudad de Destino. ¿Deseas ingresarla igual?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              ingresarOrdenTabla(dataOrden);
              inputRef.current.value = "";
            } else {
              inputRef.current.value = "";
            }
          });
        }
      } catch (error) {
        console.error("Error al buscar los datos de la orden:", error);
      } finally {
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idCliente, iataCiudad, idTransporte, ordenesDataTabla],
  );

  const ingresarOrdenTabla = async (dataOrden) => {
    try {
      if (dataOrden && dataOrden.length > 0) {
        try {
          await insertPickingTemp({
            OD: dataOrden[0].OD,
            COD_USUARIO: id_user,
            COD_CHOFER: idTransporte,
            COMUNA: iataCiudad,
            PROCESO: proceso,
          });
          setOrdenesDataTabla((prevOrdenes) => [...prevOrdenes, dataOrden[0]]);
          setTotalBultos((prevTotalBultos) => prevTotalBultos + dataOrden[0].BULTOS);
          setTotalOrdenes((prevTotalOrdenes) => prevTotalOrdenes + 1);
        } catch (error) {
          console.error("Error:", error);
        }
        inputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleIngresarOrden = async (event) => {
    await ordenIngresada(event);
  };

  const crearManifeisto = async () => {
    try {
      let via = "TERRESTRE";
      if (idTransporte === 9997 || idTransporte === 9998) {
        via = "AEREO";
      }

      const fecha = getFecha();

      const data = {
        FH_CREACION: fecha,
        FH_CIERRE: fecha,
        COD_USUARIO: id_user,
        PROCESO: proceso,
        ID_CLIENTE: 0,
        COD_CHOFER: idTransporte,
        ESTADO: "CERRADO",
        COD_OPERADOR_ENTRADA: id_user,
        COD_CHOFER_ENTRADA: idTransporte,
        NOMBRE_CHOFER_ENTRADA: nombreUser,
        SELLOS: sellos,
        CANTIDAD_VALIJAS: 0,
        TOTAL_BULTOS: totalBultos,
        TIPO_RUTA: via,
        TIPO_MANIFIESTO: proceso,
        ORIGEN: iataUser,
        DESTINO: iataUser,
        NOTA: nota,
        FH_MAIL: "",
        RUTA: "",
      };

      const response = await insertManifiesto(data);

      const datosParaEnviar = {
        COD_USUARIO: id_user,
        TIPO_MANIFIESTO: proceso,
        DESTINO: iataCiudad,
        ID_MANIFIESTO: response,
      };

      try {
        await insertPicking(datosParaEnviar);
      } catch (error) {
        console.log("Fallo el envio");
      }
      return response;
    } catch (error) {
      console.error("Error al crear el manifiesto:", error);
    }
  };

  const handleCrearManifiesto = async () => {
    setLoading(true);
    setIsbtnEnviar(false);
    if (!idCliente) {
      showError("Debe seleccionar un Cliente antes de continuar!!!");
      setLoading(false);
      return;
    } else if (!idTransporte) {
      showError("Debe seleccionar un chofer antes de continuar!!!");
      setLoading(false);
      return;
    } else if (!iataCiudad) {
      showError("Debe seleccionar una comuna antes de continuar!!!");
      setLoading(false);
      return;
    } else if (ordenesDataTabla.length === 0) {
      showError("Manifiestos debe contener una orden!!!");
      setLoading(false);
      return;
    } else {
      Swal.fire({
        title: "Seguro de Crear Manifiesto?",
        text: "Esta a punto de crear un nuevo Manifiesto!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Crear!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const numeroManifiesto = await crearManifeisto();
          await Mail_Gestor({ proceso, correoUser, numeroManifiesto, ciudadUser });

          const correoUserOperaciones = "supervisores.operacion@cargoex.cl";
          await Mail_Gestor_Sup({ proceso, correoUserOperaciones, numeroManifiesto, ciudadUser });
          Swal.fire({
            title: "Manifiesto Creado",
            text: "N°: " + numeroManifiesto,
            icon: "success",
          });
          try {
            await downloadManifestPdfGlobal(numeroManifiesto, proceso, ciudadUser, "", true);
          } catch (error) {
            showError("Error al descargar PDF!!!");
            return;
          }
          resetState();
        }
      });
    }
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Autocomplete
                disabled
                disableClearable
                value={selectedCliente}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={clientes}
                onChange={handleChangeClientes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="CLIENTE" />}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                label="ORIGEN MANIFIESTO"
                value={ciudadUser}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Autocomplete
                disableClearable
                value={selectedCiudad}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={ciudades}
                onChange={handleChangeCiudades}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => <Paper sx={{ width: "100%", textAlign: "left" }}>{children}</Paper>}
                renderInput={(params) => <TextField {...params} label="DESTINO" />}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Autocomplete
                disableClearable
                value={selectedTransportes}
                disablePortal
                id="size-small-outlined-multi-ch"
                size="small"
                options={transportes}
                onChange={handleChangeTransportes}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => <Paper sx={{ width: "100%", textAlign: "left" }}>{children}</Paper>}
                renderInput={(params) => <TextField {...params} label="TRANSPORTE" />}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                name="FH_ACTUAL"
                label="Fecha"
                sx={{ width: "100%" }}
                value={fechaActual}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                size="small"
                id="outlined-size-small"
                fullWidth
                label="SELLOS"
                multiline
                rows={1}
                name="message"
                value={sellos}
                onChange={handleChangeSellos}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                size="small"
                id="outlined-size-small"
                fullWidth
                label="Nota"
                multiline
                rows={1}
                name="message"
                value={nota}
                onChange={handleChangeNota}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} justifyContent="flex-end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleCrearManifiesto}
                disabled={isbtnEnviar}
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper style={{ padding: "10px", marginTop: "10px" }}>
        <form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Ingrese CODIGO BARRA"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    ordenIngresada(e);
                  }
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleIngresarOrden}
              >
                INGRESAR
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                ORDENES: {totalOrdenes}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                BULTOS: {totalBultos}
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Grid style={{ marginTop: "10px" }}>
              <TablaManifiestos rows={ordenesDataTabla} columns={columns} loading={loadingEliminar} />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
export default ManifiestosRetornoRef;
